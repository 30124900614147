import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Token } from '@angular/compiler';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  generateNewToken(formData: FormData): Observable<Token> {
    return this.http.post<Token>(this.apiUrl + "api/tokens/addNewToken", formData);
  }

  getAllTokens(): Observable<Token[]> {
    return this.http.get<Token[]>(this.apiUrl + "api/tokens/allTokens");
  }

  getTokenByUser(email: any): Observable<Token[]> {
    return this.http.get<Token[]>(this.apiUrl + "api/tokens/byUser/" + email);
  }

  deleteTokenById(id: any): Observable<void> {
    return this.http.delete<void>(this.apiUrl + "api/tokens/delete/" + id);
  }

}
