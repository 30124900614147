import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.css']
})
export class MainNavComponent {

  constructor(private authService: AuthService) { }

  public get isLoggedIn(){
    if(!this.authService.loggedIn()){
      return true;
    }else{
      return false;
    }
  }

}
