<app-column-two>
    <nav class="navbar navbar-light navbar-expand-md bg-light justify-content-md-center justify-content-start">
        <div class="navbar-collapse collapse justify-content-between align-items-center w-100" id="collapsingNavbar2">
            <ul class="navbar-nav text-md-center text-left">
            </ul>
            <ul class="nav navbar-nav flex-row justify-content-md-center justify-content-start flex-nowrap">
                <!-- Search form -->
                <form class="form-inline md-form form-sm active-cyan-2" style="display: flex;">
                    <button class="btn btn-sm btn-outline-info ml-2">
                        <span class="fa fa-search"></span>
                    </button>
                    <input class="form-control form-control-sm mr-3 w-75" type="text" placeholder="Search" aria-label="Search" #input (keyup)="applyFilter($event)">
                </form>
                <button class="btn btn-gold ml-2" style="text-transform: initial;" (click)="createTranscription()">
                    <span class="fa fa-plus"> New Transcription</span>
                </button>
            </ul>
        </div>
    </nav>
    <div style="padding: 50px; height: 80%; box-sizing: border-box;">
        <mat-card style="height: 100%; box-sizing: border-box; padding:0">
            <!-- <file-explorer [fileElements]="fileElements | async" [path]="currentPath" [canNavigateUp]="canNavigateUp" (folderAdded)="addFolder($event)" (elementRemoved)="removeElement($event)" (navigatedDown)="navigateToFolder($event)" (navigatedUp)="navigateUp()"
                (elementRenamed)="renameElement($event)" (elementMoved)="moveElement($event)">
            </file-explorer> -->
            <file-explorer [fileElements]="fileElements | async" [path]="currentPath" [canNavigateUp]="canNavigateUp" (folderAdded)="addFolder($event)" (elementRemoved)="removeElement($event)" (navigatedDown)="navigateToFolder($event)" (navigatedUp)="navigateUp()"
                (elementRenamed)="renameElement($event)">
            </file-explorer>
        </mat-card>
    </div>
</app-column-two>