<ng-progress id="progressBar" [color]="progressBar.currentColor" [thick]="true"></ng-progress>
<ngx-alerts></ngx-alerts>
<app-column-two>
    <div class="container">

        <h2 class="text-center mt-5"><strong>Speech Analysis</strong></h2>
        <h3 class="text-center">Choose Your Input</h3>

        <div class="text-center">
            <button type="button" class="btn btn-lg m-5" style="background-color:transparent;" (click)="recordAudio()">
                <i class="fa fa-microphone"></i> | Record Audio
            </button>
            <button type="button" class="btn btn-lg m-5" style="background-color:transparent;" (click)="uploadFile()">
                <i class="fa fa-upload"></i> | Upload File
            </button>
            <button type="button" class="btn btn-lg m-5" style="background-color:transparent;" (click)="enterText()">
                <i class="fa fa-edit"></i> | Enter Text
            </button>
        </div>

        <div class="row">
            <div class="col-lg-3 col-xl-3">
            </div>
            <div class="col-lg-6 col-xl-6">
                <!-- Enter Text -->
                <div class="card card-white grid-margin" [hidden]="!isText">
                    <div class="card-body">
                        <div class="form-group">
                            <label style="color: #b6931f;">Type your text and click on process :</label>
                            <textarea [(ngModel)]="textareaValue" class="form-control" style="text-align: right;" rows="5" placeholder="| إكتب الجملة لي بغيتي"></textarea>
                        </div>
                        <div class="loading-indicator" *ngIf="spinnerService.visibility | async">
                            <mat-spinner mode="indeterminate" color="accent"></mat-spinner>
                        </div>
                        <button type="button" class="btn btn-lg" (click)="generateSentimentWithText()"><i class="fa fa-android"></i></button>
                    </div>
                </div>
                <!-- Upload File -->
                <div class="card card-white grid-margin" [hidden]="!isFile">
                    <div class="card-body">
                        <div class="dropzone" fileDragDrop (filesChangeEmiter)="onFileChange($event)">
                            <div class="text-wrapper">
                                <div class="centered">
                                    <div class="loading-indicator" *ngIf="spinnerService.visibility | async">
                                        <mat-spinner mode="indeterminate" color="accent"></mat-spinner>
                                    </div>
                                    <input type="file" name="file" id="file" (change)="onFileChange($event)" ngModel #fileInput="ngModel" accept=".wav, .mp3, .mp4, .flac, .ogg, .amr, .oga">
                                    <label for="file"><span class="fa fa-upload textLink">Select your file</span> or
                                            Drop it here!</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Record Audio -->
                <div class="card card-white grid-margin" [hidden]="!isAudio">
                    <div class="card-body">
                        <div class="recorder_wrapper">
                            <div class="recorder">
                                <button class="start-button" *ngIf="!isRecording && !blobUrl" (click)="startRecording()"></button>

                                <button class="stop-button" *ngIf="isRecording && !blobUrl" (click)="stopRecording()"></button>

                                <button class="cancel-button" *ngIf="!isRecording && blobUrl" (click)="clearRecordedData()"></button>

                                <div class="loading-indicator" *ngIf="spinnerService.visibility | async">
                                    <mat-spinner mode="indeterminate" color="accent"></mat-spinner>
                                </div>
                                <button class="submit-button ml-3" *ngIf="!isRecording && blobUrl" (click)="submitRecording()"></button>

                                <div class="mt-3" *ngIf="isRecording && !blobUrl"> {{recordedTime}} </div>

                                <div class="mt-3">
                                    <audio *ngIf="!isRecording && blobUrl" controls>
                                        <source [src]="blobUrl" type="audio/webm">
                                    </audio>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Upload File Response -->
                <div class="card card-white grid-margin" [hidden]="!isResponseFile">
                    <div class="card-body">
                        <div class="form-group">
                            <label style="color: #b6931f;">Transcript :</label>
                            <!-- <textarea [(ngModel)]="transcriptFromFileView" [readonly]="true" class="form-control" style="text-align: right;" rows="5"></textarea> -->
                            <div class="modal-body rounded" style="border-style: groove;">
                                <p id="transcribeId" style="text-align: right;" [innerHTML]="transcriptFromFileView"></p>
                            </div>
                        </div>
                        <div class="loading-indicator" *ngIf="spinnerService.visibility | async">
                            <mat-spinner mode="indeterminate" color="accent"></mat-spinner>
                        </div>
                        <button type="button" class="btn btn-lg" (click)="generateSentimentWithTextFromFile()"><i class="fa fa-android"></i></button>
                    </div>
                </div>
                <!-- Record Audio Response -->
                <div class="card card-white grid-margin" [hidden]="!isResponseAudio">
                    <div class="card-body">
                        <div class="form-group">
                            <label style="color: #b6931f;">Transcript :</label>
                            <div class="modal-body rounded" style="border-style: groove;">
                                <p id="transcribeId" style="text-align: right;" [innerHTML]="transcriptFromFileView"></p>
                            </div>
                        </div>
                        <div class="loading-indicator" *ngIf="spinnerService.visibility | async">
                            <mat-spinner mode="indeterminate" color="accent"></mat-spinner>
                        </div>
                        <button type="button" class="btn btn-lg" (click)="generateSentimentWithTextFromAudio()"><i class="fa fa-android"></i></button>
                    </div>
                </div>
                <!-- Response -->
                <div class="card card-white grid-margin" [hidden]="!isResponseText">
                    <div class="card-body">
                        <div class="form-group">
                            <label style="color: #b6931f;">Transcript :</label>
                            <!-- <textarea [readonly]="true" class="form-control" style="text-align: right;" rows="5">{{demo?.transcribe}}</textarea> -->
                            <div class="modal-body rounded" style="border-style: groove;">
                                <p id="transcribeId" style="text-align: right;" [innerHTML]="demo?.transcribe"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-xl-3">
                <div class="card mb-3" [hidden]="!isResponseText">
                    <div class="card-body">
                        <div class="d-flex flex-column flex-lg-row">
                            <div class="row flex-fill">
                                <div class="col-sm text-center" *ngIf="demo?.sentiment == 'positive'">
                                    <h4 class="h5">Predominant Sentiment</h4>
                                    <span><i class='fa fa-smile-o' style='font-size:32px; color: green;'></i></span>
                                    <span class="badge bg-success ml-3">{{demo?.percentage}} %</span>
                                </div>
                                <div class="col-sm text-center" *ngIf="demo?.sentiment == 'negative'">
                                    <h4 class="h5">Predominant Sentiment</h4>
                                    <span><i class='fa fa-frown-o' style='font-size:32px; color: red;'></i></span>
                                    <span class="badge bg-danger ml-3">{{demo?.percentage}} %</span>
                                </div>
                                <div class="col-sm text-center" *ngIf="demo?.sentiment == 'neutral'">
                                    <h4 class="h5">Predominant Sentiment</h4>
                                    <span><i class='fa fa-meh-o' style='font-size:32px; color: #6c757d;'></i></span>
                                    <span class="badge bg-secondary ml-3">{{demo?.percentage}} %</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</app-column-two>