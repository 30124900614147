<app-column-one></app-column-one>
<div class="col-md-6 offset-md-3 mt-7" style="margin-top: 110px;">
    <div class="card">
        <div class="card-body">
            <div style="display: flex; justify-content: center; align-items: center;">
                <div
                    style="margin-left: 15px; text-align: center; font-size: 22px; font-weight: 600; margin-top: 5px; color: rgb(21, 44, 91);">
                    Login</div>
            </div>
            <form #loginForm="ngForm" (ngSubmit)="onSubmit(loginForm)">
                <div class="controlFrom">
                    <div class="input-group mb-3">
                        <div class="input-group-append">
                            <span class="input-group-text"><i class="fa fa-envelope"></i></span>
                        </div>
                        <input type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" name="email" ngModel
                            required #emailInput="ngModel" class="form-control" id="loginEmail"
                            placeholder="name@k-integrate.com">
                    </div>
                    <span class="help-block" style="color:red;" *ngIf="emailInput.invalid && emailInput.touched">Please
                        enter a valid email</span>
                </div>
                <div class="controlFrom">
                    <div class="input-group mb-3">
                        <div class="input-group-append">
                            <span class="input-group-text"><i class="fa fa-key"></i></span>
                        </div>
                        <input type="password" name="password" ngModel required #passwordInput="ngModel"
                            class="form-control" id="loginPassword" placeholder="********">
                    </div>
                    <span class="help-block" style="color:red;"
                        *ngIf="passwordInput.invalid && passwordInput.touched">Please
                        enter a password</span>
                </div>
                <div class="form-group controlFrom">
                    <button type="submit" [disabled]="loginForm.invalid || showLoading"
                        class="btn btn-gold form-control">
                        <i *ngIf="showLoading" class="fa fa-spinner fa-spin"></i>&nbsp;&nbsp;
                        <span *ngIf="showLoading">Loading...</span>
                        <span *ngIf="!showLoading">Login<i class="fa fa-sign-in"></i></span>
                    </button>
                </div>
                <div class="form-group form-check" style="margin-left: 30px !important;">
                    <a style="color: #000;" [routerLink]="['/reset-password']">Forgot Password?</a>
                </div>
                <div class="form-group form-check" style="margin-left: 30px !important;">
                    <a style="color: #000;" [routerLink]="['/register']">Don't Have an Account Yet?</a>
                </div>
            </form>
        </div>
    </div>
</div>