import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ProgressBarService } from 'src/app/shared/services/progress-bar.service';
import { AlertService } from 'ngx-alerts';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  model: any = {};

  constructor(private route: ActivatedRoute, private authService: AuthService, public progressBar: ProgressBarService,
    private alertService: AlertService, private router: Router) { }

  ngOnInit() {
    this.model.token = this.route.snapshot.queryParamMap.get('token');
    this.model.userid = this.route.snapshot.queryParamMap.get('amp;userid');
  }
  changePassword() {
    this.alertService.info('Working on changing password');
    this.progressBar.startLoading();
    this.authService.changePassword(this.model).subscribe(() => {
      this.progressBar.setSuccess();
      console.log("success");
      this.alertService.success('Password Changed');
      this.progressBar.completeLoading();
      this.router.navigateByUrl('/login');
    }, err => {
      this.progressBar.setError();
      console.log(err);
      this.alertService.danger(err.error);
      this.progressBar.completeLoading();
    })
  }

}
