import { Component, OnInit } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { AlertService } from 'ngx-alerts';
import { Subscription } from 'rxjs';
import { ProgressBarService } from 'src/app/shared/services/progress-bar.service';
import { UserServiceService } from '../../services/user-service.service';

@Component({
  selector: 'app-co-occurence',
  templateUrl: './co-occurence.component.html',
  styleUrls: ['./co-occurence.component.css']
})
export class CoOccurenceComponent implements OnInit {
  public fileName!: string | null;
  public fileNameKeyWords!: string | null;
  public excelFile!: File;
  public excelFileKeyWords!: File;
  public columns: any[] = [];
  public columnsKeyWords: any[] = [];
  public files: any;
  public filesKeyWords: any;
  public refreshing!: boolean;
  private subscriptions: Subscription[] = [];
  public filterSelected: FormControl = new FormControl();
  public columnSelected: FormControl = new FormControl();
  public columnKeyWordsSelected: FormControl = new FormControl();

  constructor(public progressBar: ProgressBarService, private alertService: AlertService, private userService: UserServiceService) {
  }

  ngOnInit(): void {
  }

  public onExcelFileChange(event: any): void {
    const filesEvent = Object.keys(event.target.files).map((key: any) => event.target.files[key]);
    this.files = filesEvent[0];
    this.fileName = this.files.name;
    this.excelFile = this.files;
    
    this.progressBar.startLoading();
    const formData = new FormData();
    formData.append("excelFile", this.excelFile);
    this.subscriptions.push(
      this.userService.getDataColumnsNameCSV(formData).subscribe(
        (response: any) => {
          this.columns = response;
          this.progressBar.setSuccess();
          this.progressBar.completeLoading();
        },
        (errorResponse: any) => {
          this.progressBar.setError();
          this.alertService.danger("An error occurred while processing the request.");
          this.progressBar.completeLoading();
        }
      )
    );
  }

  public onExcelFileChangeKeyWords(event: any): void {
    const filesEvent = Object.keys(event.target.files).map((key: any) => event.target.files[key]);
    this.filesKeyWords = filesEvent[0];
    this.fileNameKeyWords = this.filesKeyWords.name;
    this.excelFileKeyWords = this.filesKeyWords;
    
    this.progressBar.startLoading();
    const formData = new FormData();
    formData.append("excelFile", this.excelFileKeyWords);
    this.subscriptions.push(
      this.userService.getDataColumnsNameCSV(formData).subscribe(
        (response: any) => {
          this.columnsKeyWords = response;
          this.progressBar.setSuccess();
          this.progressBar.completeLoading();
        },
        (errorResponse: any) => {
          this.progressBar.setError();
          this.alertService.danger("An error occurred while processing the request.");
          this.progressBar.completeLoading();
        }
      )
    );
  }

  public onGenerateCoOccurenceForm(coOccurenceForm: NgForm): void {
    this.progressBar.startLoading();
    this.refreshing = true;
    const formData = new FormData();

    formData.append("customFile", this.excelFile);
    formData.append("customFileKeyWords", this.excelFileKeyWords);
    formData.append("columnNameKeyWords", this.columnKeyWordsSelected.value);
    formData.append("columnName", this.columnSelected.value);
    formData.append("filterName", this.filterSelected.value);
    this.subscriptions.push(
      this.userService.generateCoOccurence(formData).subscribe(
        (response: Blob) => {
          const file = new window.Blob([response]);
          const downloadAncher = document.createElement("a");
          downloadAncher.style.display = "none";
          const fileURL = URL.createObjectURL(file);
          downloadAncher.href = fileURL;
          downloadAncher.download = `Co-Ocuurence_${new Date()}.csv`;
          downloadAncher.click();
          coOccurenceForm.reset();
          this.columns = [];
          this.columnsKeyWords = [];
          this.fileName = null;
          this.fileNameKeyWords = null;
          this.refreshing = false;
          this.progressBar.setSuccess();
          this.progressBar.completeLoading();
        },
        (errorResponse: any) => {
          this.progressBar.setError();
          this.alertService.danger("An error occurred while processing the request.");
          this.progressBar.completeLoading();
          this.refreshing = false;
        }
      )
    );
  }

}
