<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Element deletion</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p><strong>Are you sure you want to delete <span class="text-primary">{{elementName}}</span> element ?</strong></p>
    <p>All information associated to this element will be permanently deleted.
        <span class="text-danger">This operation can not be undone.</span>
    </p>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">Cancel</button>
    <button type="button" class="btn btn-danger" ngbAutofocus (click)="onDelete()">Ok</button>
</div>