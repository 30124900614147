import { Component, OnInit } from '@angular/core';
import { ProgressBarService } from "src/app/shared/services/progress-bar.service";
import { AlertService } from "ngx-alerts";
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserServiceService } from '../../services/user-service.service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {
  userEmail: string | null;
  userFormGroup?: FormGroup;
  public submitted: boolean = false;

  constructor(private activatedRoute: ActivatedRoute,
    private userEdit: UserServiceService, public progressBar: ProgressBarService,
    private alertService: AlertService, private fb: FormBuilder, private router: Router) {
    this.userEmail = localStorage.getItem('user')?.split('"')[1] || null;
  }

  ngOnInit(): void {
    this.userEdit.getUser(this.userEmail)
      .subscribe(user => {
        this.userFormGroup = this.fb.group({
          id: [user.id, Validators.required],
          first_name: [user.first_name, Validators.required],
          last_name: [user.last_name, Validators.required],
          email: [user.email, Validators.required],
          phone: [user.phone, Validators.required]
        })
      });
  }

  onUpdateUser() {
    this.alertService.info('Working on updating user');
    this.progressBar.startLoading();
    this.userEdit.updateUser(this.userFormGroup?.value).subscribe(() => {
      this.progressBar.setSuccess();
      console.log("success");
      this.alertService.success('User updated');
      this.progressBar.completeLoading();
      // this.router.navigateByUrl('/dashboard');
    }, err => {
      this.progressBar.setError();
      console.log(err);
      this.alertService.danger(err.error);
      this.progressBar.completeLoading();
    })
  }

}
