<app-column-one></app-column-one>
<div class="col-md-6 offset-md-3 mt-7" style="margin-top: 110px;">
    <div class="card">
        <div class="card-body">
            <div style="display: flex; justify-content: center; align-items: center;">
                <div style="margin-left: 15px; text-align: center; font-size: 22px; font-weight: 600; margin-top: 5px; color: rgb(21, 44, 91);">
                    Reset Password</div>
            </div>
            <form #f="ngForm" (ngSubmit)="onSubmit(f)">

                <div class="form-group controlFrom">
                    <label for="resetPasswordEmail">Account Email</label>
                    <input type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" name="email" ngModel required #email="ngModel" class="form-control" id="resetPasswordEmail" placeholder="name@k-integrate.com">
                </div>

                <div class="form-group controlFrom">
                    <button type="submit" [disabled]="f.invalid" class="btn btn-gold form-control">Reset Password
                        <i class="fa fa-paper-plane"></i></button>
                </div>
            </form>
        </div>
    </div>
</div>