<mat-nav-list>
    <div class="logo py-4">
        <img class="logoImg" src="./assets/logo.svg" alt="Company Logo" />
    </div>
    <a mat-list-item routerLinkActive="list-item-active" routerLink="/transcriptions" [hidden]="isLoggedIn">
        <mat-icon>graphic_eq</mat-icon> Transcription
    </a>
    <a mat-list-item routerLinkActive="list-item-active" routerLink="/userfolder" [hidden]="isLoggedIn">
        <mat-icon>cloud</mat-icon> Word Cloud
    </a>
    <a mat-list-item routerLinkActive="list-item-active" routerLink="/wordembedding" [hidden]="isLoggedIn">
        <mat-icon>assessment</mat-icon> Word Embedding
    </a>
    <a mat-list-item routerLinkActive="list-item-active" routerLink="/sentimentanalysis" [hidden]="isLoggedIn">
        <mat-icon>sentiment_satisfied_alt</mat-icon> Sentiment Analysis
    </a>
    <a mat-list-item routerLinkActive="list-item-active" routerLink="/co-occurence" [hidden]="isLoggedIn">
        <mat-icon>extension</mat-icon> Co-occurence
    </a>
    <a mat-list-item routerLinkActive="list-item-active" routerLink="/demo">
        <mat-icon>build</mat-icon> Demo
    </a>
    <a mat-list-item routerLinkActive="list-item-active" routerLink="/dashboard">
        <mat-icon>dashboard</mat-icon> Demo Dashboard
    </a>
</mat-nav-list>