<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Delete Confirmation</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p><strong>{{fName}}</strong> will be deleted. Do you confirm?</p>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">Cancel</button>
    <button type="button" class="btn btn-danger" ngbAutofocus (click)="confirm()">Confirm</button>
</div>