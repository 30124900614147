import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { User } from 'src/app/shared/models/user';
import { Transcript } from '../models/transcript.model';
import { wordCloud } from '../models/wordcloud.model';
import { Language } from '../models/language.model';
import { Folder } from '../models/folder.model';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class UserServiceService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  static toExportFileName(): string {
    return `export_${new Date()}.xlsx`;
  }

  public exportAsExcelFile(json: any[]): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    XLSX.writeFile(workbook, UserServiceService.toExportFileName());
  }

  getUser(email: any): Observable<User> {
    return this.http.get<User>(this.apiUrl +"api/users/" + email);
  }

  getUserById(id: any): Observable<User> {
    return this.http.get<User>(this.apiUrl + "api/users/byId/" + id);
  }

  getAllUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.apiUrl + "api/users/getAll");
  }

  getAllTranscribe(email: any): Observable<Transcript[]> {
    return this.http.get<Transcript[]>(this.apiUrl + "api/transcribes/" + email);
  }

  getFrequencyWords(transcribeId: number): Observable<wordCloud[]> {
    return this.http.get<wordCloud[]>(this.apiUrl + "api/transcribes/frequencyword/" + transcribeId);
  }

  getTranscribe(folderId: number): Observable<Transcript[]> {
    return this.http.get<Transcript[]>(this.apiUrl + "api/folders/folderId/" + folderId);
  }

  updateUser(user: User): Observable<User> {
    localStorage.setItem("user_name", user.first_name + " " + user.last_name);
    return this.http.put<User>(this.apiUrl + "api/users/update/" + user.id, user);
  }

  changePasswordUser(model: any) {
    return this.http.post(this.apiUrl + "api/auth/userchangepassword", model);
  }

  updateNameFolder(id: any, jsonData: any) {
    return this.http.put<User>(this.apiUrl + "api/folders/update/" + id, jsonData);
  }

  updateNameTranscribe(id: any, jsonData: any) {
    return this.http.put<User>(this.apiUrl + "api/transcribes/update/" + id, jsonData);
  }

  deleteTranscribe(id: any): Observable<void> {
    return this.http.delete<void>(this.apiUrl + "api/transcribes/delete/" + id);
  }

  deleteFolder(id: any): Observable<void> {
    return this.http.delete<void>(this.apiUrl + "api/folders/delete/" + id);
  }

  generateWordCloud(allTranscribe: string): Observable<Blob> {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return this.http.post<Blob>(this.apiUrl + "api/transcribes/WordCloud", allTranscribe, httpOptions);
  }

  generateWordEmbedding(allTranscribe: string): Observable<Blob> {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return this.http.post<Blob>(this.apiUrl + "api/transcribes/WordEmbedding", allTranscribe, httpOptions);
  }

  public generateWordEmbeddingByExcel(formData: FormData): Observable<Blob> {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return this.http.post<Blob>(this.apiUrl + "api/transcribes/WordEmbeddingByExcel", formData, httpOptions);
  }

  public generateSentimentAnalysisByExcel(formData: FormData): Observable<Blob> {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return this.http.post<Blob>(this.apiUrl + "api/transcribes/SentimentAnalysisByExcel", formData, httpOptions);
  }

  public generateCoOccurence(formData: FormData): Observable<Blob> {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return this.http.post<Blob>(this.apiUrl + "api/transcribes/CoOccurence", formData, httpOptions);
  }

  public generateCustomWordCloud(formData: FormData): Observable<Blob> {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return this.http.post<Blob>(this.apiUrl + "api/transcribes/CustomWOrdCloud", formData, httpOptions);
  }

  generateWordCloudById(id: number): Observable<Blob> {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return this.http.post<Blob>(this.apiUrl + "api/transcribes/WordCloudById", id, httpOptions);
  }

  generateWordCloudSelected(jsonData: any): Observable<Blob> {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return this.http.post<Blob>(this.apiUrl + "api/transcribes/WordCloudSelected", jsonData, httpOptions);
  }

  getDataColumnsName(formData: FormData): Observable<any> {
    return this.http.post<any>(this.apiUrl + "api/transcribes/getDataColumnsNameFromExcel", formData);
  }

  getDataColumnsNameCSV(formData: FormData): Observable<any> {
    return this.http.post<any>(this.apiUrl + "api/transcribes/getDataColumnsNameFromExcelSentiment", formData);
  }

  getDataSheetName(formData: FormData): Observable<any> {
    return this.http.post<any>(this.apiUrl + "api/transcribes/getDataSheetNameFromExcel", formData);
  }

  getAllLanguage(): Observable<Language[]> {
    return this.http.get<Language[]>(this.apiUrl + "api/languages");
  }

  getAllFolder(email: any): Observable<Folder[]> {
    return this.http.get<Folder[]>(this.apiUrl + "api/folders/" + email);
  }

  addFolder(model: any) {
    return this.http.post(this.apiUrl + "api/folders/add", model);
  }
}
