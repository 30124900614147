import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserServiceService } from 'src/app/user-dashboard/services/user-service.service';

@Component({
  selector: 'app-modal-folder',
  templateUrl: './modal-folder.component.html',
  styleUrls: ['./modal-folder.component.css']
})
export class ModalFolderComponent implements OnInit {
  jsonData: any = {};
  userEmail: any;

  constructor(public activeModal: NgbActiveModal, private userService: UserServiceService) {
    this.userEmail = localStorage.getItem('user')?.split('"')[1];
  }

  ngOnInit(): void {
  }

  @ViewChild("folderName") folderName!: any;
  createFolder() {
    this.jsonData['folderName'] = this.folderName.nativeElement.value;
    this.jsonData['user'] = this.userEmail;
    this.userService.addFolder(this.jsonData)
      .subscribe((res) => {
        this.activeModal.close('Ok click')
      });
  }

}
