import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'ngx-alerts';
import { ProgressBarService } from 'src/app/shared/services/progress-bar.service';
import { UserServiceService } from '../../services/user-service.service';
import { ModalWordCloudComponent } from '../modals/modal-word-cloud/modal-word-cloud.component';
import { NgbdModalConfirmComponent } from '../modals/ngbd-modal-confirm/ngbd-modal-confirm.component';
import { DomSanitizer } from '@angular/platform-browser';
import { SelectionModel } from '@angular/cdk/collections';
import { wordCloud } from '../../models/wordcloud.model';
import { ModalTranscribeComponent } from '../modals/modal-transcribe/modal-transcribe.component';

@Component({
  selector: 'app-frequency-words',
  templateUrl: './frequency-words.component.html',
  styleUrls: ['./frequency-words.component.css']
})
export class FrequencyWordsComponent implements AfterViewInit, OnInit {
  userEmail: any;
  htmlFile: any;
  image: any;
  jsonData: any = {};
  transcribeId!: number;
  folderId!: number;
  allTranscribe: string = "";
  allTranscribeSelected: string = "";
  displayedColumns: string[] = ['select', 'file', 'text', 'wordCloud', 'delete'];
  public dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel<wordCloud>(true, []);

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(private userService: UserServiceService, private modalService: NgbModal, private router: Router, private activatedRoute: ActivatedRoute,
    private alertService: AlertService, public progressBar: ProgressBarService, private sanitizer: DomSanitizer) {
    this.userEmail = localStorage.getItem('user')?.split('"')[1];
    this.folderId = activatedRoute.snapshot.params.id;
  }

  ngOnInit(): void {
    this.userService.getTranscribe(this.folderId)
      .subscribe((res) => {
        this.dataSource.data = res;
        for (var i = 0; i < res.length; i++) {
          this.allTranscribe += " " + res[i].text
        }
      });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: wordCloud): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.frequency + 1}`;
  }

  deleteTranscribe(index: number) {
    const modalRef = this.modalService.open(NgbdModalConfirmComponent);
    modalRef.componentInstance.file = this.dataSource.data[index]['file'];
    modalRef.componentInstance.fileId = this.dataSource.data[index]['id'];
    modalRef.result.then(
      () => {
        this.userService.getTranscribe(this.folderId)
          .subscribe((res) => {
            this.dataSource.data = res;
          });
        // actions
      },
      () => {
        // actions
      });
  }

  showTranscribe(index: number) {
    const modalRef = this.modalService.open(ModalTranscribeComponent, { scrollable: true });
    modalRef.componentInstance.transcribe = this.dataSource.data[index]['text'];
  }

  showWordCloud(index: number) {
    this.transcribeId = this.dataSource.data[index]['id'];
    this.router.navigateByUrl('/wordcloud/' + this.transcribeId);
  }

  // generateAllWordCloud() {
  //   this.alertService.info('Generating Word Cloud');
  //   this.progressBar.startLoading();
  //   const allWordCloudObserver = {
  //     next: (x: any) => {
  //       let objectURL = URL.createObjectURL(x);
  //       this.image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
  //       this.progressBar.setSuccess();
  //       this.progressBar.completeLoading();
  //       const modalRef = this.modalService.open(ModalWordCloudComponent, { size: 'lg' });
  //       modalRef.componentInstance.image = this.image;
  //       modalRef.componentInstance.fileBlob = x;
  //     },
  //     error: (err: any) => {
  //       this.progressBar.setError();
  //       this.alertService.danger("Error");
  //       this.progressBar.completeLoading();
  //     }
  //   };
  //   this.userService.generateWordCloud(this.allTranscribe).subscribe(allWordCloudObserver);
  // }

  generateWordCloudSelected() {
    this.jsonData = this.selection.selected;
    for (let i = 0; i < this.jsonData.length; i++) {
      this.allTranscribeSelected += " " + this.jsonData[i]['text'];
    }
    this.alertService.info('Generating Word Cloud');
    this.progressBar.startLoading();
    const allWordCloudObserver = {
      next: (x: any) => {
        let objectURL = URL.createObjectURL(x);
        this.image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        this.progressBar.setSuccess();
        this.progressBar.completeLoading();
        const modalRef = this.modalService.open(ModalWordCloudComponent, { size: 'lg' });
        modalRef.componentInstance.image = this.image;
        modalRef.componentInstance.fileBlob = x;
      },
      error: (errorResponse: any) => {
        this.progressBar.setError();
        this.sendErrorNotification(errorResponse.error.message)
        this.progressBar.completeLoading();
      }
    };
    this.userService.generateWordCloud(this.allTranscribeSelected).subscribe(allWordCloudObserver);
  }

  generateWordEmbeddingSelected(){
    this.jsonData = this.selection.selected;
    for (let i = 0; i < this.jsonData.length; i++) {
      this.allTranscribeSelected += " " + this.jsonData[i]['text']
    }
    const allWordEmbeddingObserver = {
      next: (httpResponse: any) => {
        const file = new window.Blob([httpResponse], { type: 'application/html' });
        const downloadAncher = document.createElement("a");
        downloadAncher.style.display = "none";
        const fileURL = URL.createObjectURL(file);
        downloadAncher.href = fileURL;
        downloadAncher.download = "WordEmbedding.html";
        downloadAncher.click();
      },
      error: (errorResponse: any) => {
        this.progressBar.setError();
        this.sendErrorNotification(errorResponse.error.message)
        this.progressBar.completeLoading();
      }
    };
    this.userService.generateWordEmbedding(this.allTranscribeSelected).subscribe(allWordEmbeddingObserver);
  }

  private sendErrorNotification(message: string): void {
    if (message) {
      this.alertService.danger(message);
    } else {
      this.alertService.danger("Please select at least one Transcribe");
    }
  }
}