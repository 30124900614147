import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AlertService } from 'ngx-alerts';
import { Subscription } from 'rxjs';
import { ProgressBarService } from 'src/app/shared/services/progress-bar.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { Demo } from '../../models/demo';
import { Transcript } from '../../models/transcript.model';
import { AudioRecordingService } from '../../services/audio-recording.service';
import { DemoService } from '../../services/demo.service';

@Component({
  selector: 'app-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.css']
})
export class DemoComponent implements OnInit, OnDestroy {
  public files: any;
  public isText!: boolean;
  public isResponseText!: boolean;
  public isFile!: boolean;
  public isResponseFile!: boolean;
  public isAudio!: boolean;
  public isResponseAudio!: boolean;
  public textareaValue!: string;
  private subscriptions: Subscription[] = [];
  public transcriptFromFile!: string;
  public transcriptFromFileView!: string;
  public demo!: Demo;
  public isRecording = false;
  public recordedTime: any;
  public blobUrl: any;
  public teste: any;

  constructor(public progressBar: ProgressBarService, public spinnerService: SpinnerService, private alertService: AlertService,
    private demoService: DemoService, private router: Router, private audioRecordingService: AudioRecordingService, private sanitizer: DomSanitizer) {
    this.audioRecordingService
      .recordingFailed()
      .subscribe(() => (this.isRecording = false));
    this.audioRecordingService
      .getRecordedTime()
      .subscribe((time: any) => (this.recordedTime = time));
    this.audioRecordingService.getRecordedBlob().subscribe((data: { blob: Blob | MediaSource; }) => {
      this.teste = data;
      this.blobUrl = this.sanitizer.bypassSecurityTrustUrl(
        URL.createObjectURL(data.blob)
      );
    });
  }

  ngOnInit(): void {
    this.isText = false;
    this.isResponseText = false;
    this.isFile = false;
    this.isResponseFile = false;
    this.isAudio = false;
    this.isResponseAudio = false;
    this.files = null;
  }

  public uploadFile() {
    this.isText = false;
    this.isResponseText = false;
    this.isFile = true;
    this.isResponseFile = false;
    this.isAudio = false;
    this.isResponseAudio = false;
    this.files = null;
    this.blobUrl = null;
    this.isRecording = false;
  }

  public recordAudio() {
    this.isText = false;
    this.isResponseText = false;
    this.isFile = false;
    this.isResponseFile = false;
    this.isAudio = true;
    this.isResponseAudio = false;
    this.blobUrl = null;
    this.isRecording = false;
  }

  public enterText() {
    this.isText = true;
    this.isResponseText = false;
    this.isFile = false;
    this.isResponseFile = false;
    this.isAudio = false;
    this.isResponseAudio = false;
  }

  public generateSentimentWithText() {
    const formData = new FormData();
    formData.append("transcribe", this.textareaValue);
    formData.append("source", "Text");
    this.spinnerService.show()
    this.subscriptions.push(
      this.demoService.generateSentimentWithText(formData).subscribe(
        (response: Demo[]) => {
          this.demo = response[0];
          this.demo["transcribe"] = this.breakline_speech(response[0]['transcribe'].split(" "));
          this.textareaValue = '';
          this.isText = false;
          this.isResponseText = true;
          this.isFile = false;
          this.isResponseFile = false;
          this.isAudio = false;
          this.isResponseAudio = false;
        },
        (errorResponse: any) => {
          this.progressBar.setError();
          this.alertService.danger("An error occurred while processing the request.");
          this.progressBar.completeLoading();
        }
      )
    );
  }

  public onFileChange(event: any) {
    const filesEvent = Object.keys(event.target.files).map((key: any) => event.target.files[key]);
    this.files = filesEvent[0];
    const formData = new FormData();
    formData.append("file[]", this.files);
    formData.append("lang", "ar-MA");
    formData.append("service", "Microsoft");
    this.spinnerService.show()
    this.subscriptions.push(
      this.demoService.audioToText(formData).subscribe(
        (response: Transcript[]) => {
          this.transcriptFromFile = response[0]['text'];
          this.transcriptFromFileView = this.breakline_speech(response[0]['text'].split(" "));
          this.isText = false;
          this.isResponseText = false;
          this.isFile = false;
          this.isResponseFile = true;
          this.isAudio = false;
          this.isResponseAudio = false;
          this.files = null;
        },
        (errorResponse: any) => {
          this.progressBar.setError();
          this.alertService.danger("An error occurred while processing the request.");
          this.progressBar.completeLoading();
        }
      )
    );
  }

  public generateSentimentWithTextFromFile() {
    const formData = new FormData();
    formData.append("transcribe", this.transcriptFromFile);
    formData.append("source", "File");
    this.spinnerService.show()
    this.subscriptions.push(
      this.demoService.generateSentimentWithText(formData).subscribe(
        (response: Demo[]) => {
          this.demo = response[0];
          this.demo["transcribe"] = this.breakline_speech(response[0]['transcribe'].split(" "));
          this.textareaValue = '';
          this.isText = false;
          this.isResponseText = true;
          this.isFile = false;
          this.isResponseFile = false;
          this.isAudio = false;
          this.isResponseAudio = false;
        },
        (errorResponse: any) => {
          this.progressBar.setError();
          this.alertService.danger("An error occurred while processing the request.");
          this.progressBar.completeLoading();
        }
      )
    );
  }

  public generateSentimentWithTextFromAudio() {
    const formData = new FormData();
    formData.append("transcribe", this.transcriptFromFile);
    formData.append("source", "Recording");
    this.spinnerService.show()
    this.subscriptions.push(
      this.demoService.generateSentimentWithText(formData).subscribe(
        (response: Demo[]) => {
          this.demo = response[0];
          this.demo["transcribe"] = this.breakline_speech(response[0]['transcribe'].split(" "));
          this.textareaValue = '';
          this.isText = false;
          this.isResponseText = true;
          this.isFile = false;
          this.isResponseFile = false;
          this.isAudio = false;
          this.isResponseAudio = false;
        },
        (errorResponse: any) => {
          this.progressBar.setError();
          this.alertService.danger("An error occurred while processing the request.");
          this.progressBar.completeLoading();
        }
      )
    );
  }

  public redirectToDashboard() {
    this.router.navigateByUrl('/dashboard');
  }

  public startRecording() {
    if (!this.isRecording) {
      this.isRecording = true;
      this.audioRecordingService.startRecording();
    }
  }

  public abortRecording() {
    if (this.isRecording) {
      this.isRecording = false;
      this.audioRecordingService.abortRecording();
    }
  }

  public stopRecording() {
    if (this.isRecording) {
      this.audioRecordingService.stopRecording();
      this.isRecording = false;
    }
  }

  public clearRecordedData() {
    this.blobUrl = null;
  }

  public submitRecording(): void {
    const formData = new FormData();
    formData.append('audio', this.teste.blob, this.teste.title);
    formData.append("lang", "ar-MA");
    formData.append("service", "Microsoft");
    this.spinnerService.show()
    this.subscriptions.push(
      this.demoService.recordAudioToText(formData).subscribe(
        (response: Transcript[]) => {
          this.transcriptFromFile = response[0]['text'];
          this.transcriptFromFileView = this.breakline_speech(response[0]['text'].split(" "));
          this.isText = false;
          this.isResponseText = false;
          this.isFile = false;
          this.isResponseFile = false;
          this.isAudio = false;
          this.isResponseAudio = true;
        },
        (errorResponse: any) => {
          this.progressBar.setError();
          this.alertService.danger("An error occurred while processing the request.");
          this.progressBar.completeLoading();
        }
      )
    );
  }

  private breakline_speech(listString: any[]): string {
    var regroupetext = "";
    regroupetext += listString[0];
    var rexp = new RegExp("^[a-zA-Z]")
    for (var i = 1; i < listString.length; i++) {
      var current = rexp.test(listString[i]);
      var previous = rexp.test(listString[i - 1]);
      if (previous == current) {
        regroupetext += " " + listString[i];
      } else {
        regroupetext += "<br>" + listString[i];
      }
    }
    return regroupetext;
  }

  ngOnDestroy(): void {
    this.abortRecording();
  }

}
