<app-column-one></app-column-one>
<div class="col-md-6 offset-md-3 mt-7" style="margin-top: 110px;">
    <div class="card">
        <div class="card-body">
            <div style="display: flex; justify-content: center; align-items: center;">
                <div
                    style="margin-left: 15px; text-align: center; font-size: 22px; font-weight: 600; margin-top: 5px; color: rgb(21, 44, 91);">
                    Email Confirmation</div>
            </div>
            <form style="margin-top: 35px;">
                <div class="alert alert-primary" *ngIf="emailConfirmed" role="alert">
                    Email confirmed
                </div>
                <div class="alert alert-warning" *ngIf="!emailConfirmed" role="alert">
                    Unable to confirm email
                </div>
            </form>
        </div>
    </div>
</div>