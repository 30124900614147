<app-column-one></app-column-one>
<div class="col-md-6 offset-md-3 mt-4">
    <div class="card">
        <div class="card-body">
            <div style="display: flex; justify-content: center; align-items: center;">
                <div style="margin-left: 15px; text-align: center; font-size: 22px; font-weight: 600; margin-top: 5px; color: rgb(21, 44, 91);">
                    Login to Your Account</div>
            </div>
            <form #f="ngForm" (ngSubmit)="onSubmit(f)">
                <div class="form-group controlFrom">
                    <label for="registerFirstname">First Name</label>
                    <input type="text" name="firstname" ngModel required #firstname="ngModel" class="form-control" id="registerFirstname" placeholder="First Name">
                </div>
                <div class="form-group controlFrom">
                    <label for="registerLastname">Last Name</label>
                    <input type="text" name="lastname" ngModel required #lastname="ngModel" class="form-control" id="registerLastname" placeholder="Last Nom">
                </div>
                <div class="form-group controlFrom">
                    <label for="registerEmail">Email</label>
                    <input type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" name="email" ngModel required #email="ngModel" class="form-control" id="registerEmail" placeholder="name@k-integrate.com">
                </div>
                <div class="form-group controlFrom">
                    <label for="registerPhone">Phone</label>
                    <input type="tel" name="phone" pattern="[0-9]{10}" ngModel required #phone="ngModel" class="form-control" id="registerPhone" placeholder="0666666667">
                </div>
                <div class="form-group controlFrom">
                    <label for="registerPassword">Password</label>
                    <input type="password" name="password" ngModel required #password="ngModel" class="form-control" id="registerPassword" placeholder="********" pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$" title="Passwords must contain at least eight characters, including uppercase, lowercase letters and numbers.">
                </div>
                <div class="form-group controlFrom">
                    <label for="registerConfirmPassword">Confirm Password</label>
                    <input type="password" name="confirmPassword" ngModel required #confirmPassword="ngModel" class="form-control" id="registerConfirmPassword" placeholder="********">
                </div>
                <div class="form-group controlFrom">
                    <button type="submit" [disabled]="f.invalid" class="btn btn-gold form-control">Login <i
                            class="fa fa-sign-in"></i></button>
                </div>
                <div class="form-group form-check" style="margin-left: 30px !important;">
                    <a style="color: #000;" [routerLink]="['/login']">Back to Login</a>
                </div>
            </form>
        </div>
    </div>
</div>