import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProgressBarService } from 'src/app/shared/services/progress-bar.service';
import { AlertService } from 'ngx-alerts';
import { UserServiceService } from '../../services/user-service.service';

@Component({
  selector: 'app-user-change-password',
  templateUrl: './user-change-password.component.html',
  styleUrls: ['./user-change-password.component.css']
})
export class UserChangePasswordComponent implements OnInit {

  model: any = {};

  constructor(private userService: UserServiceService, public progressBar: ProgressBarService,
    private alertService: AlertService, private router: Router) { }

  ngOnInit(): void {
    this.model.email = localStorage.getItem("user");
  }

  changePasswordUser() {
    this.alertService.info('Working on changing password');
    this.progressBar.startLoading();
    this.userService.changePasswordUser(this.model).subscribe(() => {
      this.progressBar.setSuccess();
      console.log("success");
      this.alertService.success('Password Changed');
      this.progressBar.completeLoading();
      this.router.navigateByUrl('/transcriptions');
    }, err => {
      this.progressBar.setError();
      console.log(err);
      this.alertService.danger(err.error);
      this.progressBar.completeLoading();
    })
  }

}
