// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl: "http://localhost:5000/",
  // confirmEmailUrl: "http://localhost:4200/confirm-email/",
  // changePasswordUrl: "http://localhost:4200/change-password/",
  apiUrl: "https://www.integrateanalytics.com:5000/",
  confirmEmailUrl: "https://www.integrateanalytics.com/confirm-email/",
  changePasswordUrl: "https://www.integrateanalytics.com/change-password/",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
