import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Demo } from '../models/demo';
import { Transcript } from '../models/transcript.model';

@Injectable({
  providedIn: 'root'
})
export class DemoService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  generateSentimentWithText(formData: FormData): Observable<Demo[]> {
    return this.http.post<Demo[]>(this.apiUrl + "api/demos/sentiment", formData);
  }

  audioToText(formData: FormData): Observable<Transcript[]>{
    return this.http.post<Transcript[]>(this.apiUrl + "api/demos/transcription", formData);
  }

  recordAudioToText(formData: FormData): Observable<Transcript[]>{
    return this.http.post<Transcript[]>(this.apiUrl + "api/demos/record-audio", formData);
  }

  getAllDemos(): Observable<Demo[]> {
    return this.http.get<Demo[]>(this.apiUrl + "api/demos/allDemos");
  }

  addNewContact(formData: FormData): Observable<void>{
    return this.http.post<void>(this.apiUrl + "api/demos/addNewContact", formData);
  }

}
