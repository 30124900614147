<div class="modal-header">
    <h4 class="modal-title">Create a new Transcription</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Select Folder</h5>
            <div class="row">
                <div class="col-9">
                    <select class="form-control form-select" [formControl]='selectControlFolder'>
                            <option disabled [value]="null">Select Folder</option>
                            <option *ngFor="let folder of allFolder" [value]="folder.id">
                                {{ folder.name }}
                            </option>
                        </select>
                </div>
                <div class="col-3">
                    <button class="btn btn-secondary" (click)="createFolder()">
                            <span class="fa fa-folder-open"></span>
                            New Folder
                        </button>
                </div>
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Upload your files</h5>
            <div class="row">
                <div>
                    <div>
                        <div class="dropzone" fileDragDrop (filesChangeEmiter)="onFileChange($event)">
                            <div class="text-wrapper">
                                <div class="centered">
                                    <input type="file" name="file" id="file" (change)="onFileChange($event)" multiple ngModel required #fileInput="ngModel" accept=".wav, .mp3, .mp4, .flac, .ogg, .amr, .oga">
                                    <label for="file"><span class="fa fa-upload textLink">Select your file</span> or
                                            Drop it here!</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <h3>Files</h3>
                    <div class="row fileItem" *ngFor="let f of files; let ind = index">
                        <div class="col-sm-12 fileItemIconDiv" (click)="openConfirmDialog(ind)">
                            <i class="fa-lg fileItemIcon"></i>
                            <div class="col-sm-12 fileItemText">
                                <span>{{f.name}}</span>
                            </div>
                        </div>
                    </div>
                    <!--
                    <div>
                        <mat-list>
                            <h3 mat-subheader>Files</h3>
                            <mat-list-item *ngFor="let file of files">
                                <mat-icon mat-list-icon>folder</mat-icon>
                                <h4 mat-line>{{file.name}}</h4>
                                <button mat-raised-button color="warn" (click)="deleteFile(file)">Delete</button>
                                <mat-divider></mat-divider>
                            </mat-list-item>
                        </mat-list>
                    </div>
                  -->
                </div>
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Select service</h5>
            <div class="row">
                <div class="col">
                    <select class="form-control form-select" [formControl]='selectControlService'>
                        <option disabled [value]="null">Select Service</option>
                        <option value="Google">Service 1</option>
                        <option value="Microsoft">Service 2</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Select audio language</h5>
            <div class="row">
                <div class="col">
                    <select class="form-control form-select" [formControl]='selectControlLanguage'>
                            <option disabled [value]="null">Select Language</option>
                            <option *ngFor="let language of allLanguage" [value]="language.code">
                                {{ language.name }}
                            </option>
                        </select>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <!-- <mat-spinner [diameter]="50" *ngIf="spinnerService.visibility | async"></mat-spinner> -->
    <div class="loading-indicator" *ngIf="spinnerService.visibility | async">
        <mat-spinner mode="indeterminate" color="accent"></mat-spinner>
    </div>
    <button type="submit" (click)="onSubmit()" class="btn btn-gold" style="width:100%">Submit</button>
</div>