<ng-progress id="progressBar" [color]="progressBar.currentColor" [thick]="true"></ng-progress>
<ngx-alerts></ngx-alerts>
<mat-toolbar>
    <mat-toolbar-row class="justify-content-between">
        <div class="navbar-header">
            <a class="navbar-brand" routerLink="/">Integrate Analytics</a>
        </div>
        <div>
            <a class="navbar-brand" routerLink="/">Solutions</a>
            <a class="navbar-brand" routerLink="/">Pricing</a>
            <a class="navbar-brand" routerLink="/">Contact Us</a>
        </div>
        <div>
            <a class="navbar-brand" routerLink="/login">Login</a>
            <button class="btn btn-gold" routerLink="/register">Get Started</button>
        </div>
    </mat-toolbar-row>
</mat-toolbar>