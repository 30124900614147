<app-column-one></app-column-one>
<div class="col-md-6 offset-md-3 mt-7" style="margin-top: 110px;">
    <div class="card">
        <div class="card-body">
            <div style="display: flex; justify-content: center; align-items: center;">
                <div style="margin-left: 15px; text-align: center; font-size: 22px; font-weight: 600; margin-top: 5px; color: rgb(21, 44, 91);">
                    Change Password</div>
            </div>
            <form #f="ngForm" (ngSubmit)="changePassword()">

                <div class="form-group controlFrom">
                    <label for="changePassword controlFrom">New Password</label>
                    <input type="password" name="password" [(ngModel)]="model.password" required #password="ngModel" class="form-control" id="changePassword" placeholder="*********" title="Passwords must contain at least eight characters, including uppercase, lowercase letters and numbers."
                        pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$">
                </div>

                <div class="form-group controlFrom">
                    <label for="changeCofirmPassword controlFrom">Confirm Password</label>
                    <input type="password" name="confirmPassword" [(ngModel)]="model.confirmPassword" required #confirmPassword="ngModel" class="form-control" id="changeConfirmPassword" placeholder="*********">
                </div>

                <div class="form-group controlFrom">
                    <button type="submit" [disabled]="f.invalid" class="btn btn-gold form-control">Change
                        Password <i class="fa fa-save"></i></button>
                </div>
            </form>
        </div>
    </div>
</div>