<app-column-two>
    <div class="m-2">
        <nav class="navbar navbar-expand-md breadcrumb">
            <div class="collapse navbar-collapse" id="navbarCollapse" style="background-color: #fafafa;">
                <div class="nav nav-pills ml-5">
                    <a class="nav-item nav-link breadcrumb-item" data-toggle="tab" href="#twc">Transcription word cloud</a>
                    <a class="nav-item nav-link breadcrumb-item" data-toggle="tab" href="#cwc">Custom word cloud</a>
                </div>
            </div>
        </nav>
    </div>

    <!-- main content -->
    <div class="tab-content mt-3" id="myTabContent">

        <!-- Transcription word cloud -->
        <div class="tab-pane fade show active" id="twc">
            <nav class="navbar navbar-light navbar-expand-md bg-light justify-content-md-center justify-content-start">
                <div class="navbar-collapse collapse justify-content-between align-items-center w-100" id="collapsingNavbar2">
                    <ul class="navbar-nav text-md-center text-left">
                    </ul>
                    <ul class="nav navbar-nav flex-row justify-content-md-center justify-content-start flex-nowrap">
                        <!-- Search form -->
                        <form class="form-inline md-form form-sm active-cyan-2" style="display: flex;">
                            <input class="form-control form-control-sm mr-3 w-75" type="text" placeholder="Search" aria-label="Search" #input (keyup)="applyFilter($event)">
                            <button class="btn btn-sm btn-outline-info ml-2">
                        <span class="fa fa-search"></span>
                    </button>
                        </form>
                    </ul>
                </div>
            </nav>
            <div style="width: 90%; margin-left: 60px; margin-top: 60px;">
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 demo-table">

                    <ng-container matColumnDef="demo-position">
                        <th mat-header-cell *matHeaderCellDef> No. </th>
                        <td mat-cell *matCellDef="let i = index"> {{ i+1 }} </td>
                    </ng-container>

                    <ng-container matColumnDef="demo-name">
                        <th mat-header-cell *matHeaderCellDef> Folder Name </th>
                        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>

                    <ng-container matColumnDef="showTranscribe">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                        <td mat-cell *matCellDef="let i = index">
                            <button class="btn btn-sm btn-outline-info" (click)="showTranscribe(i)">Show Transcribes</button>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="download">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                        <td mat-cell *matCellDef="let i = index">
                            <button class="btn btn-success" (click)="downloadFolder(i)"><i class="fa fa-download"></i></button>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="delete">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                        <td mat-cell *matCellDef="let i = index">
                            <button class="btn btn-danger" (click)="deleteFolder(i)"><i class="fa fa-trash"></i></button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </div>

        <!-- Custom word cloud -->
        <div class="tab-pane fade show" id="cwc">
            <div class="col-md-6 offset-md-3 mt-7" style="margin-top: 50px;">
                <div class="card">
                    <div class="card-body">
                        <div style="display: flex; justify-content: center; align-items: center;">
                            <div style="margin-left: 15px; text-align: center; font-size: 22px; font-weight: 600; margin-top: 5px; color: rgb(21, 44, 91);">
                                Custom word cloud</div>
                        </div>
                        <div class="container">
                            <form #customWordCloudForm="ngForm" (ngSubmit)="onGenerateCustomWordCloud(customWordCloudForm)" class="form mt-5">
                                <div class="row">
                                    <div class="col">
                                        <div class="row">
                                            <div class="input-group mb-2">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">Upload File </span>
                                                </div>
                                                <div class="custom-file">
                                                    <input type="file" name="customFile" (change)="onExcelFileChange($event)" class="custom-file-input" ngModel accept=".csv">
                                                    <label class="custom-file-label">
                                                    <span [hidden]="!fileName">{{fileName}}</span>
                                                    <span [hidden]="fileName">Choose File</span>
                                                </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="columns.length">
                                            <div class="form-group">
                                                <label>Column Name</label>
                                                <select name="columnName" [formControl]='columnSelected' required ngModel class="form-select form-select-sm">
                                                <option *ngFor="let column of columns" [value]="column">
                                                    {{ column }}
                                                </option>
                                            </select>
                                            </div>
                                            <div class="form-group">
                                                <label>Sentiment Filter</label>
                                                <select name="sentimentFilter" [formControl]='filterSelected' required ngModel class="form-select form-select-sm">
                                                <option value="positive">Positive</option>
                                                <option value="negative">Negative</option>
                                                <option value="Neutral">Neutral</option>
                                            </select>
                                            </div>
                                            <div class="form-group">
                                                <label>Number of words</label>
                                                <input (keypress)="keyPress($event)" name="numberOfWords" id="numberOfWords" required ngModel type="text" class="form-control form-control-sm">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col d-flex justify-content-end">
                                        <button class="btn btn-secondary form-control-sm ml-2" type="submit">
                                        <i *ngIf="refreshing" class="fa fa-spinner fa-spin"></i>
                                        <i *ngIf="!refreshing" class="fa fa-floppy-o"></i>&nbsp;&nbsp;
                                        <span *ngIf="refreshing">Loading...</span>
                                        <span *ngIf="!refreshing">Submit</span>
                                    </button>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</app-column-two>