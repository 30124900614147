import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserServiceService } from 'src/app/user-dashboard/services/user-service.service';

@Component({
  selector: 'app-modal-folder-deletion',
  templateUrl: './modal-folder-deletion.component.html',
  styleUrls: ['./modal-folder-deletion.component.css']
})
export class ModalFolderDeletionComponent implements OnInit {
  @Input() folder: any;
  @Input() folderId: any;

  constructor(public modal: NgbActiveModal, private userService: UserServiceService) { }

  ngOnInit(): void {
  }

  onDelete() {
    this.userService.deleteFolder(this.folderId)
      .subscribe(data => {
        this.modal.close('Ok click')
      });
  }

}
