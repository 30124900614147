<div class="card-body">
    <form #userForm="ngForm" (ngSubmit)="submitForm(userForm.value)">
        <div style="display: flex; justify-content: center; align-items: center;">
            <div style="margin-left: 15px; text-align: center; font-size: 18px; font-weight: 500; margin-top: 5px; color: rgb(21, 44, 91);">
                Tell us more about you!</div>
        </div>
        <div>
            <label for="first_name">First Name:</label>
            <input class="form-control-sm" type="text" id="first_name" name="first_name" required [(ngModel)]="first_name" [disabled]="disableFields">
        </div>
        <div>
            <label for="last_name">Last Name:</label>
            <input class="form-control-sm" type="text" id="last_name" name="last_name" required [(ngModel)]="last_name" [disabled]="disableFields">
        </div>
        <div>
            <label for="company">Company:</label>
            <input class="form-control-sm" type="text" id="company" name="company" required [(ngModel)]="company" [disabled]="disableFields">
        </div>
        <div>
            <label for="function">Function:</label>
            <select class="form-control-sm" id="function" name="function" required [(ngModel)]="function" [disabled]="disableFields">
                <option value="advertising-creative" selected="selected">Advertising/Creative</option>
                <option value="ceo-president-owner">CEO/President/Owner</option>
                <option value="customer-experience">Customer Experience</option>
                <option value="data-science-engineering">Data Science/Engineering</option>
                <option value="finance">Finance</option>
                <option value="innovation-r-and-d">Innovation/R&amp;D</option>
                <option value="marketing">Marketing</option>
                <option value="market-research-and-insights">Market Research &amp; Insights</option>
                <option value="sales">Sales</option>
                <option value="technology">Technology</option>
                <option value="other">Other</option>
            </select>
        </div>
        <div>
            <label for="email">Email or Phone:</label>
            <input class="form-control-sm" type="email" id="email" name="email" required [(ngModel)]="email" [disabled]="disableFields">
        </div>
        <div style="display: flex;">
            <input type="checkbox" id="public" name="public" [(ngModel)]="public" (change)="disableFields = public">
            <label class="form-control-sm" class="mr-3 mt-1" for="public">Or select this button if you do not want to share your contact information</label>
        </div>
        <div style="text-align: right;">
            <button type="submit">Submit</button>
        </div>
    </form>
</div>