import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserServiceService } from 'src/app/user-dashboard/services/user-service.service';

@Component({
  selector: 'app-ngbd-modal-confirm',
  templateUrl: './ngbd-modal-confirm.component.html',
  styleUrls: ['./ngbd-modal-confirm.component.css']
})
export class NgbdModalConfirmComponent implements OnInit {
  @Input() file: any;
  @Input() fileId: any;

  constructor(public modal: NgbActiveModal, private userService: UserServiceService) { }

  ngOnInit(): void {
  }

  onDelete() {
    this.userService.deleteTranscribe(this.fileId)
      .subscribe(data => {
        this.modal.close('Ok click')
      });
  }

}
