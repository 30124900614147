import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-word-cloud',
  templateUrl: './modal-word-cloud.component.html',
  styleUrls: ['./modal-word-cloud.component.css']
})
export class ModalWordCloudComponent implements OnInit {
  @Input() image: any;
  @Input() fileBlob: any;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void { }

  downloadAllWordCloud() {
    const file = new window.Blob([this.fileBlob], { type: 'application/jpeg' });
    const downloadAncher = document.createElement("a");
    downloadAncher.style.display = "none";
    const fileURL = URL.createObjectURL(file);
    downloadAncher.href = fileURL;
    downloadAncher.download = "allWordCloud.jpeg";
    downloadAncher.click();
  }

}
