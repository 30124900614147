import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserServiceService } from 'src/app/user-dashboard/services/user-service.service';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.css']
})
export class DeleteDialogComponent implements OnInit {
  @Input() elementName: any;
  @Input() elementId: any;
  @Input() isFolder: any;

  constructor(public modal: NgbActiveModal, private userService: UserServiceService) { }

  ngOnInit(): void {
  }

  onDelete() {
    if (this.isFolder == true) {
      this.elementId = this.elementId.split('FOLDER')[1];
      this.userService.deleteFolder(this.elementId)
        .subscribe(data => {
          this.modal.close('Ok click')
        });
    } else {
      this.elementId = this.elementId.split('FILE')[1];
      this.userService.deleteTranscribe(this.elementId)
        .subscribe(data => {
          this.modal.close('Ok click')
        });
    }
  }

}
