import { NgModule } from '@angular/core';

import { ColumnTwoComponent } from './layouts/column-two/column-two.component';
import { MainHeaderComponent } from './components/main-header/main-header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MainNavComponent } from './components/main-nav/main-nav.component';
import { CommonModule } from '@angular/common';
import { ColumnOneComponent } from './layouts/column-one/column-one.component';
import { HeaderComponent } from './components/header/header.component';
import { NgProgressModule } from '@ngx-progressbar/core';

import { AlertModule } from 'ngx-alerts';
// import { BsDropdownModule } from 'ngx-bootstrap';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { RouterModule } from '@angular/router';
import { AuthGuard } from './services/auth-guard.service';
import { LoggedInAuthGuard } from './services/logged-in-auth-guard.service';
import { TokenComponent } from './components/token/token.component';
import { MatTableModule } from '@angular/material/table';

@NgModule({
  declarations: [ColumnTwoComponent, MainHeaderComponent, MainNavComponent, ColumnOneComponent, HeaderComponent, TokenComponent],
  imports: [
    CommonModule,
    RouterModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatDividerModule,
    MatTableModule,
    MatListModule,
    NgProgressModule,
    BsDropdownModule.forRoot(),

    // Specify your library as an import (set timeout to -1 for unlimited timeout, the message can only be closed by the user clicking on it)
    AlertModule.forRoot({ maxMessages: 5, timeout: 5000, positionX: 'right' })
  ],
  exports: [
    ColumnTwoComponent,
    ColumnOneComponent,
    MatProgressSpinnerModule
  ],
  providers:[
    AuthGuard,
    LoggedInAuthGuard]
})
export class SharedModule { }
