import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.css']
})
export class DialogConfirmComponent implements OnInit {

  public fName!: string;
  public fIndex: any;

  constructor(public modal: NgbActiveModal) { }

  ngOnInit() {
  }

  confirm() {
    this.modal.close(this.fIndex);
  }
}