<app-column-two>
    <ng-progress id="progressBar" [color]="progressBar.currentColor" [thick]="true"></ng-progress>
    <ngx-alerts></ngx-alerts>
    <div class="col-md-6 offset-md-3 mt-7" style="margin-top: 50px;">
        <div class="card">
            <div class="card-body">
                <div style="display: flex; justify-content: center; align-items: center;">
                    <div style="margin-left: 15px; text-align: center; font-size: 22px; font-weight: 600; margin-top: 5px; color: rgb(21, 44, 91);">
                        Change Password</div>
                </div>
                <form #f="ngForm" (ngSubmit)="changePasswordUser()">

                    <div class="form-group controlFrom">
                        <label for="oldChangePasswordUser controlFrom">Old Password</label>
                        <input type="password" name="oldPasswordUser" [(ngModel)]="model.oldPasswordUser" required #password="ngModel" class="form-control" id="oldChangePasswordUser" placeholder="*********" title="Passwords must contain at least eight characters, including uppercase, lowercase letters and numbers."
                            pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$">
                    </div>

                    <div class="form-group controlFrom">
                        <label for="newChangePasswordUser controlFrom">New Password</label>
                        <input type="password" name="newPasswordUser" [(ngModel)]="model.newPasswordUser" required #password="ngModel" class="form-control" id="newChangePasswordUser" placeholder="*********" title="Passwords must contain at least eight characters, including uppercase, lowercase letters and numbers."
                            pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$">
                    </div>

                    <div class="form-group controlFrom">
                        <label for="changeCofirmPasswordUser controlFrom">Confirm Password</label>
                        <input type="password" name="confirmPasswordUser" [(ngModel)]="model.confirmPasswordUser" required #confirmPassword="ngModel" class="form-control" id="changeConfirmPassword" placeholder="*********">
                    </div>

                    <div class="form-group controlFrom">
                        <button type="submit" [disabled]="f.invalid" class="btn btn-gold form-control">Change
                            Password <i class="fa fa-save"></i></button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</app-column-two>