<app-column-two>
    <ng-progress id="progressBar" [color]="progressBar.currentColor" [thick]="true"></ng-progress>
    <ngx-alerts></ngx-alerts>
    <div class="container">
        <div class="row">
            <div class="col-xl-6 mb-3 mb-lg-5">
                <div class="card">
                    <div class="card-body">
                        <span class="d-block text-center font-weight-bold mb-2">Sentiment Score</span>
                        <canvas #doughnutCanvas></canvas>
                    </div>
                </div>
            </div>

            <div class="col-xl-6 mb-3 mb-lg-5">
                <div class="card">
                    <div class="card-body" [hidden]="!chooseList">
                        <span class="d-block text-center font-weight-bold mb-2">Choose your inputs</span>
                        <form #chooseListForm="ngForm" (ngSubmit)="apply()" class="form">
                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Sentiment</label>
                                        <select name="sentiment" [formControl]='sentimentSelected' class="form-select form-select-sm">
                                            <option value="positive">Positive</option>
                                            <option value="negative">Negative</option>
                                            <option value="neutral">Neutral</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>Service</label>
                                        <select name="service" [formControl]='serviceSelected' class="form-select form-select-sm">
                                            <option value="detailedComments">Comments</option>
                                            <option value="wordCloud">Word Cloud</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col d-flex justify-content-end">
                                    <button class="btn btn-secondary form-control-sm ml-2" type="submit">
                                        <i *ngIf="refreshing" class="fa fa-spinner fa-spin"></i>
                                        <i *ngIf="!refreshing" class="fa fa-floppy-o"></i>&nbsp;&nbsp;
                                        <span *ngIf="refreshing">Loading...</span>
                                        <span *ngIf="!refreshing">Apply</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="card-body" [hidden]="!detailedComments" class="overflow-auto" style="height: 632px;">
                        <i class="fa fa-arrow-left m-2" style="font-size:1.5vw;" (click)="backToMenu()"></i>
                        <span class="d-block text-center font-weight-bold mb-4">Comments</span>
                        <div *ngIf="sentimentSelected.value == 'positive'">
                            <div class="modal-body rounded m-1" style="border-style: groove;" *ngFor="let pos of demosPositive">
                                <p id="transcribeIdPositive" style="text-align: right;" [innerHTML]="pos?.transcribe"></p>
                            </div>
                        </div>
                        <div *ngIf="sentimentSelected.value == 'negative'">
                            <div class="modal-body rounded m-1" style="border-style: groove;" *ngFor="let neg of demosNegative">
                                <p id="transcribeIdNegative" style="text-align: right;" [innerHTML]="neg?.transcribe"></p>
                            </div>
                        </div>
                        <div *ngIf="sentimentSelected.value == 'neutral'">
                            <div class="modal-body rounded m-1" style="border-style: groove;" *ngFor="let neut of demosNeutral">
                                <p id="transcribeIdNeutral" style="text-align: right;" [innerHTML]="neut?.transcribe"></p>
                            </div>
                        </div>
                    </div>
                    <div class="card-body" [hidden]="!wordCloud">
                        <i class="fa fa-arrow-left" style="font-size:1.5vw;" (click)="backToMenu()"></i>
                        <span class="d-block text-center font-weight-bold mb-2">Most used keywords</span>
                        <div class="modal-body">
                            <img [src]="image" class="img-thumbnail" alt="An error occurred while processing the request">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-column-two>