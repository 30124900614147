import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-transcribe',
  templateUrl: './modal-transcribe.component.html',
  styleUrls: ['./modal-transcribe.component.css']
})
export class ModalTranscribeComponent implements OnInit {
  @Input() transcribe: any;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    var array = this.transcribe.split(" ");
    this.transcribe = this.breakline_speech(array);
    if (this.breakline_speech(array).includes('azertyuiopqsdfghjklmwxcvbn') == false) {
      const outputTrans = document.getElementById('transcribeId');
      if (outputTrans) outputTrans.style.textAlign = 'right';
      const outputClose = document.getElementById('closeId');
      if (outputClose) outputClose.style.float = 'left';
    }
  }

  private breakline_speech(listString: any[]): string {
    var regroupetext = "";
    regroupetext += listString[0];
    var rexp= new RegExp("^[a-zA-Z]")
    for (var i = 1; i < listString.length; i++) {
      var current = rexp.test(listString[i]);
      var previous = rexp.test(listString[i-1]);
      if (previous == current) {
        regroupetext += " " + listString[i];
      } else {
        regroupetext += "<br>" + listString[i];
      }
    }
    return regroupetext;
  }

}
