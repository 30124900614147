import { Component, OnInit } from "@angular/core";
import { NgProgress } from "@ngx-progressbar/core";
import { ProgressBarService } from "../../services/progress-bar.service";
import { AuthService } from "../../services/auth.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"]
})
export class HeaderComponent implements OnInit {
  constructor(
    private progress: NgProgress,
    public progressBar: ProgressBarService,
    public authService: AuthService
  ) { }

  ngOnInit() {
    this.progressBar.progressRef = this.progress.ref("progressBar");
  }

}
