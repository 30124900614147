<div class="modal-header">
    <h4 class="modal-title">Transcribe</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" style="border-style: groove;">
    <p id="transcribeId" [innerHTML]="transcribe"></p>
</div>
<div id="closeId" class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div>