import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserServiceService } from 'src/app/user-dashboard/services/user-service.service';

@Component({
  selector: 'app-rename-dialog',
  templateUrl: './rename-dialog.component.html',
  styleUrls: ['./rename-dialog.component.css']
})
export class RenameDialogComponent implements OnInit {
  @Input() elementName: any;
  @Input() elementId: any;
  @Input() isFolder: any;
  jsonData: any = {};

  constructor(public activeModal: NgbActiveModal, private userService: UserServiceService) {
  }

  ngOnInit(): void {
  }

  @ViewChild("ElementName") ElementName!: any;
  updateName() {
    this.jsonData['ElementName'] = this.ElementName.nativeElement.value;
      if (this.isFolder == true) {
        this.elementId = this.elementId.split('FOLDER')[1];
        this.userService.updateNameFolder(this.elementId, this.jsonData)
          .subscribe(data => {
            this.activeModal.close('Ok click')
          });
      } else {
        this.elementId = this.elementId.split('FILE')[1];
        this.userService.updateNameTranscribe(this.elementId, this.jsonData)
          .subscribe(data => {
            this.activeModal.close('Ok click')
          });
      }
  }

}
