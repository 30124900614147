import { Component, ViewChild, OnInit, ElementRef } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import Chart, { ChartItem, ChartOptions } from 'chart.js/auto'
import { AlertService } from 'ngx-alerts';
import { ProgressBarService } from 'src/app/shared/services/progress-bar.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { Demo } from '../../models/demo';
import { DemoService } from '../../services/demo.service';
import { UserServiceService } from '../../services/user-service.service';
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  public chart: any;
  public image: any;
  public demos: Demo[] = [];
  public demosNegative: Demo[] = [];
  public demosPositive: Demo[] = [];
  public demosNeutral: Demo[] = [];
  public allTranscribePositive: string = "";
  public allTranscribeNegative: string = "";
  public allTranscribeNeutral: string = "";
  public wordCloud: boolean = false;
  public detailedComments: boolean = false;
  public chooseList: boolean = false;
  public refreshing!: boolean;
  public sentimentSelected: FormControl = new FormControl();
  public serviceSelected: FormControl = new FormControl();

  @ViewChild('doughnutCanvas', { static: false }) canvas!: { nativeElement: ChartItem; };

  constructor(public progressBar: ProgressBarService, public spinnerService: SpinnerService, private alertService: AlertService, private demoService: DemoService,
    private sanitizer: DomSanitizer, private userService: UserServiceService) { }

  ngOnInit(): void {
    this.chooseList = true;
    this.detailedComments = false;
    this.wordCloud = false;
  }

  ngAfterViewInit() {
    this.demoService.getAllDemos()
      .subscribe((response: Demo[]) => {
        const demos = response.map((obj) => {
          return { ...obj, date: new Date(obj.created_at) };
        });
        this.demos = demos.sort(
          (objA, objB) => objB.date.getTime() - objA.date.getTime(),
        );
        for (var i = 0; i < this.demos.length; i++) {
          if (this.demos[i]['sentiment'] == 'negative') {
            this.allTranscribeNegative += " " + this.demos[i]['transcribe'];
            let demoNegativeObj = {} as Demo;
            demoNegativeObj.id = this.demos[i]['id'];
            demoNegativeObj.transcribe = this.demos[i]['transcribe'];
            demoNegativeObj.source = this.demos[i]['source'];
            demoNegativeObj.percentage = this.demos[i]['percentage'];
            demoNegativeObj.sentiment = this.demos[i]['sentiment'];
            demoNegativeObj.created_at = this.demos[i]['created_at'];
            this.demosNegative.push(demoNegativeObj);
          } else if (this.demos[i]['sentiment'] == 'positive') {
            this.allTranscribePositive += " " + this.demos[i]['transcribe'];
            let demoPositiveObj = {} as Demo;
            demoPositiveObj.id = this.demos[i]['id'];
            demoPositiveObj.transcribe = this.demos[i]['transcribe'];
            demoPositiveObj.source = this.demos[i]['source'];
            demoPositiveObj.percentage = this.demos[i]['percentage'];
            demoPositiveObj.sentiment = this.demos[i]['sentiment'];
            demoPositiveObj.created_at = this.demos[i]['created_at'];
            this.demosPositive.push(demoPositiveObj);
          } else if (this.demos[i]['sentiment'] == 'neutral') {
            this.allTranscribeNeutral += " " + this.demos[i]['transcribe'];
            let demoNeutralObj = {} as Demo;
            demoNeutralObj.id = this.demos[i]['id'];
            demoNeutralObj.transcribe = this.demos[i]['transcribe'];
            demoNeutralObj.source = this.demos[i]['source'];
            demoNeutralObj.percentage = this.demos[i]['percentage'];
            demoNeutralObj.sentiment = this.demos[i]['sentiment'];
            demoNeutralObj.created_at = this.demos[i]['created_at'];
            this.demosNeutral.push(demoNeutralObj);
          }
        }
        this.chart = new Chart(this.canvas.nativeElement, {
          type: 'doughnut',
          data: {
            datasets: [{
              data: [(this.demosPositive.length / this.demos.length) * 100, (this.demosNeutral.length / this.demos.length) * 100, (this.demosNegative.length / this.demos.length) * 100],
              backgroundColor: [
                '#55B4B0',
                '#DFCFBE',
                '#E15D44',
              ],
              borderColor: [
                '#55B4B0',
                '#DFCFBE',
                '#E15D44',
              ],
              borderWidth: 2,
              label: 'Percentage %',
            }],
            labels: [
              'Positive',
              'Neutral',
              'Negative'
            ]
          },
          options: {
            responsive: true,
            // cutout: 200,
            plugins: {
              datalabels: {
                color: 'white',
                formatter: (value, ctx: any) => {
                  const label = ctx.chart.data.labels[ctx.dataIndex];
                  return Math.round(value) + '%';
                }
              },
              legend: {
                labels: {
                  font: {
                    size: 10,
                  },
                  usePointStyle: true,
                  padding: 15,
                },
                display: true,
                // position: 'bottom',
              },
              tooltip: {
                enabled: true,
                yAlign: 'bottom',
                // backgroundColor: '#FFFFFF',
                bodyFontColor: '#232D34',
                bevelWidth: 3,
                bevelHighlightColor: 'rgba(255, 255, 255, 0.75)',
                bevelShadowColor: 'rgba(0, 5, 15, 0.5)',
                shadowOffsetX: 3,
                shadowOffsetY: 3,
                shadowBlur: 10,
                shadowColor: 'rgba(0, 5, 15, 0.5)',
              },
            }
          } as ChartOptions,
          plugins: [ChartDataLabels],
        });
      });
  }

  public apply() {
    if (this.serviceSelected.value == "detailedComments") {
      this.chooseList = false;
      this.detailedComments = true;
      this.wordCloud = false;
    } else if (this.serviceSelected.value == "wordCloud") {
      if (this.sentimentSelected.value == "positive") {
        this.refreshing = true;
        this.userService.generateWordCloud(this.allTranscribePositive)
          .subscribe((response: any) => {
            let objectURL = URL.createObjectURL(response);
            this.image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
            this.refreshing = false;
            this.chooseList = false;
            this.detailedComments = false;
            this.wordCloud = true;
          }),
          (errorResponse: any) => {
            this.progressBar.setError();
            this.alertService.danger("An error occurred while processing the request.");
            this.progressBar.completeLoading();
            this.refreshing = false;
          }
      } else if (this.sentimentSelected.value == "negative") {
        this.refreshing = true;
        this.userService.generateWordCloud(this.allTranscribeNegative)
          .subscribe((response: any) => {
            let objectURL = URL.createObjectURL(response);
            this.image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
            this.refreshing = false;
            this.chooseList = false;
            this.detailedComments = false;
            this.wordCloud = true;
          }),
          (errorResponse: any) => {
            this.progressBar.setError();
            this.alertService.danger("An error occurred while processing the request.");
            this.progressBar.completeLoading();
            this.refreshing = false;
          }
      } else if (this.sentimentSelected.value == "neutral") {
        this.refreshing = true;
        this.userService.generateWordCloud(this.allTranscribeNeutral)
          .subscribe((response: any) => {
            let objectURL = URL.createObjectURL(response);
            this.image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
            this.refreshing = false;
            this.chooseList = false;
            this.detailedComments = false;
            this.wordCloud = true;
          }),
          (errorResponse: any) => {
            this.progressBar.setError();
            this.alertService.danger("An error occurred while processing the request.");
            this.progressBar.completeLoading();
            this.refreshing = false;
          }
      }
    }
  }

  public backToMenu() {
    this.chooseList = true;
    this.detailedComments = false;
    this.wordCloud = false;
  }
}