import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { FileService } from '../../services/file.service';
import { FileElement } from '../../models/element';
import { ModalTranscriptionComponent } from '../modals/modal-transcription/modal-transcription.component';
import { UserServiceService } from '../../services/user-service.service';
import { DeleteDialogComponent } from '../modals/delete-dialog/delete-dialog.component';
import { RenameDialogComponent } from '../modals/rename-dialog/rename-dialog.component';
import { ModalFolderComponent } from '../modals/modal-folder/modal-folder.component';

@Component({
  selector: 'app-transcription',
  templateUrl: './transcription.component.html',
  styleUrls: ['./transcription.component.css']
})
export class TranscriptionComponent implements OnInit {
  public fileElements!: Observable<FileElement[]>;
  userEmail: any;

  constructor(private modalService: NgbModal, public fileService: FileService, private userService: UserServiceService) {
    this.userEmail = localStorage.getItem('user')?.split('"')[1];
  }

  currentRoot!: any;
  currentPath!: any;
  canNavigateUp = false;

  ngOnInit(): void {
    this.userService.getAllFolder(this.userEmail)
      .subscribe((res) => {
        for (let i = 0; i < res.length; i++) {
          const folderElement = this.fileService.add({ id: "FOLDER" + res[i]['id'], name: res[i]['name'], isFolder: true, parent: 'root' });
          this.userService.getTranscribe(res[i]['id'])
            .subscribe((data) => {
              for (let j = 0; j < data.length; j++) {
                this.fileService.add({ id: "FILE" + data[j]['id'], name: data[j]['file'], isFolder: false, parent: folderElement.id });
              }
              this.updateFileElementQuery();
            });
        }
        this.updateFileElementQuery();
      });
  }

  createTranscription() {
    const modalRef = this.modalService.open(ModalTranscriptionComponent, { size: 'lg' });
  }

  addFolder(folder: { name: string }) {
    const modalRef = this.modalService.open(ModalFolderComponent);
    modalRef.result.then(
      () => {
        this.userService.getAllFolder(this.userEmail)
          .subscribe((res) => {
            for (let i = 0; i < res.length; i++) {
              this.fileService.add({ id: "FOLDER" + res[i]['id'], name: res[i]['name'], isFolder: true, parent: 'root' });
            }
            this.updateFileElementQuery();
          });
        // actions
      },
      () => {
        // actions
      });
  }

  renameElement(element: any) {
    const modalRef = this.modalService.open(RenameDialogComponent);
    modalRef.componentInstance.elementName = element.name;
    modalRef.componentInstance.elementId = element.id;
    modalRef.componentInstance.isFolder = element.isFolder;
    modalRef.result.then(
      () => {
        this.fileService.update(element.id, { name: element.name });
        this.userService.getAllFolder(this.userEmail)
          .subscribe((res) => {
            for (let i = 0; i < res.length; i++) {
              const folderElement = this.fileService.add({ id: "FOLDER" + res[i]['id'], name: res[i]['name'], isFolder: true, parent: 'root' });
              this.userService.getTranscribe(res[i]['id'])
                .subscribe((data) => {
                  for (let j = 0; j < data.length; j++) {
                    this.fileService.add({ id: "FILE" + data[j]['id'], name: data[j]['file'], isFolder: false, parent: folderElement.id });
                  }
                  this.updateFileElementQuery();
                });
            }
            this.updateFileElementQuery();
          });
        // actions
      },
      () => {
        // actions
      });
  }

  removeElement(element: any) {
    const modalRef = this.modalService.open(DeleteDialogComponent);
    modalRef.componentInstance.elementName = element.name;
    modalRef.componentInstance.elementId = element.id;
    modalRef.componentInstance.isFolder = element.isFolder;
    modalRef.result.then(
      () => {
        this.userService.getAllFolder(this.userEmail)
          .subscribe((res) => {
            this.fileService.delete(element.id);
            this.updateFileElementQuery();
          });
        // actions
      },
      () => {
        // actions
      });
  }

  navigateToFolder(element: FileElement) {
    this.currentRoot = element;
    this.updateFileElementQuery();
    this.currentPath = this.pushToPath(this.currentPath, element.name);
    this.canNavigateUp = true;
  }

  navigateUp() {
    if (this.currentRoot && this.currentRoot.parent === 'root') {
      this.currentRoot = null;
      this.canNavigateUp = false;
      this.updateFileElementQuery();
    } else {
      this.currentRoot = this.fileService.get(this.currentRoot.parent);
      this.updateFileElementQuery();
    }
    this.currentPath = this.popFromPath(this.currentPath);
  }

  // moveElement(event: { element: any; moveTo: FileElement }) {
  //   this.fileService.update(event.element.id, { parent: event.moveTo.id });
  //   this.updateFileElementQuery();
  // }

  updateFileElementQuery() {
    this.fileElements = this.fileService.queryInFolder(this.currentRoot ? this.currentRoot.id : 'root');
  }

  pushToPath(path: string, folderName: string) {
    let p = path ? path : '';
    p += `${folderName}/`;
    return p;
  }

  popFromPath(path: string) {
    let p = path ? path : '';
    let split = p.split('/');
    split.splice(split.length - 2, 1);
    p = split.join('/');
    return p;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    filterValue.trim().toLowerCase();
    this.fileElements.subscribe((data) => {
      data.filter(element => {
        if (element.name.trim().toLowerCase() == filterValue.trim().toLowerCase()) {
          // console.log("=====", element)
         }
      }
      )
    }
    );
  }
}
