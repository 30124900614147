<app-column-two>
    <ng-progress id="progressBar" [color]="progressBar.currentColor" [thick]="true"></ng-progress>
    <ngx-alerts></ngx-alerts>
    <div class="col-md-6 offset-md-3 mt-7" style="margin-top: 50px;">
        <div class="card">
            <table mat-table [dataSource]="dataSourceToken" class="mat-elevation-z8">

                <ng-container matColumnDef="token">
                    <th mat-header-cell *matHeaderCellDef>All Your Tokens </th>
                    <td mat-cell *matCellDef="let element"> {{element.token}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsToken"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsToken;"></tr>
            </table>
        </div>
    </div>
</app-column-two>