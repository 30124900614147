<mat-toolbar>
    <mat-icon *ngIf="canNavigateUp" class="pointer" (click)="navigateUp()">arrow_back</mat-icon>
    <span style="margin-left: 8px">{{path || 'Files'}}</span>
    <span class="spacer"></span>
    <mat-icon *ngIf="!canNavigateUp" class="pointer" (click)="openNewFolderDialog()">create_new_folder</mat-icon>
</mat-toolbar>

<div class="container" fxFlex fxLayout="row" fxLayoutAlign="space-between stretch">
    <div class="content" fxFlex fxLayout="row">
        <mat-grid-list cols="8" rowHeight="100px" fxFlex>
            <mat-grid-tile *ngFor="let element of fileElements" class=file-or-folder>
                <span [matMenuTriggerFor]="rootMenu" [matMenuTriggerData]="{element: element}" #menuTrigger="matMenuTrigger"></span>
                <div fxLayout="column" fxLayoutAlign="space-between center" (click)="navigate(element)" (contextmenu)="openMenu($event, menuTrigger)">
                    <mat-icon class="file-or-folder-icon pointer gold-icon" *ngIf="element.isFolder">folder
                    </mat-icon>
                    <mat-icon class="file-or-folder-icon pointer gold-icon" *ngIf="!element.isFolder">
                        insert_drive_file</mat-icon>
                    <span>{{element.name}}</span>
                </div>
            </mat-grid-tile>
        </mat-grid-list>
    </div>
</div>

<mat-menu #rootMenu="matMenu" [overlapTrigger]="false">
    <ng-template matMenuContent let-element="element">
        <!-- <button mat-menu-item [matMenuTriggerFor]="moveToMenu" [matMenuTriggerData]="{self: element}">
            <mat-icon>open_with</mat-icon>
            <span>Move To</span>
        </button> -->
        <button mat-menu-item (click)="openRenameDialog(element)">
            <mat-icon>edit</mat-icon>
            <span>Rename</span>
        </button>
        <button mat-menu-item (click)="deleteElement(element)">
            <mat-icon>delete</mat-icon>
            <span>Delete</span>
        </button>
    </ng-template>
</mat-menu>

<!-- <mat-menu #moveToMenu="matMenu">
    <ng-template matMenuContent let-self="self">
        <ng-container *ngFor="let element of fileElements">
            <button *ngIf="element.isFolder && element.id !== self.id" mat-menu-item (click)="moveElement(self, element)">{{element.name}}</button>
        </ng-container>
    </ng-template>
</mat-menu> -->