import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FileElement } from '../../models/element';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'file-explorer',
  templateUrl: './file-explorer.component.html',
  styleUrls: ['./file-explorer.component.css']
})
export class FileExplorerComponent {

  constructor() {
  }

  @Input() fileElements!: FileElement[] | null;
  @Input() canNavigateUp!: boolean;
  @Input() path!: string;

  @Output() folderAdded = new EventEmitter<{ name: string }>();
  @Output() elementRemoved = new EventEmitter<FileElement>();
  @Output() elementRenamed = new EventEmitter<FileElement>();
  // @Output() elementMoved = new EventEmitter<{ element: FileElement; moveTo: FileElement }>();
  @Output() navigatedDown = new EventEmitter<FileElement>();
  @Output() navigatedUp = new EventEmitter();

  deleteElement(element: FileElement) {
    this.elementRemoved.emit(element);
  }

  navigate(element: FileElement) {
    if (element.isFolder) {
      this.navigatedDown.emit(element);
    }
  }

  navigateUp() {
    this.navigatedUp.emit();
  }

  // moveElement(element: FileElement, moveTo: FileElement) {
  //   this.elementMoved.emit({ element: element, moveTo: moveTo });
  // }

  openNewFolderDialog() {
    this.folderAdded.emit();
  }

  openRenameDialog(element: FileElement) {
    this.elementRenamed.emit(element);
  }

  openMenu(event: MouseEvent, viewChild: MatMenuTrigger) {
    event.preventDefault();
    viewChild.openMenu();
  }
}
