import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgProgress } from '@ngx-progressbar/core';
import { AlertService } from 'ngx-alerts';
import { AuthService } from '../../services/auth.service';
import { ProgressBarService } from '../../services/progress-bar.service';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.css']
})
export class MainHeaderComponent implements OnInit {
  @Output() toggleSidebarForMe: EventEmitter<any> = new EventEmitter();
  public isSidebarOpen: boolean = false;
  public user_name!: any;

  constructor(
    private progress: NgProgress,
    public progressBar: ProgressBarService,
    public authService: AuthService,
    private alertService: AlertService,
    private router: Router) { }

  ngOnInit() {
    this.progressBar.progressRef = this.progress.ref("progressBar");
    this.user_name = localStorage.getItem("user_name")?.replace(/['"]+/g, '');
    // this.toggleSidebar();
  }

  toggleSidebar() {
    // if (this.isSidebarOpen) {
    //     this.isSidebarOpen = false;
    // } else {
    //     this.isSidebarOpen = true;
    // }
    this.toggleSidebarForMe.emit();
  }

  changePassword() {
    this.router.navigateByUrl('/user-change-password');
  }

  userEdit() {
    this.router.navigateByUrl('/editprofile');
  }

  generateToken() {
    this.router.navigateByUrl('/token');
  }

  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("user_name");
    this.authService.currentUser = null;
    this.authService.decodedToken = null;
    this.alertService.success("Logged Out");
    this.router.navigateByUrl('/login');
  }

  public get isLoggedIn(){
    if(!this.authService.loggedIn()){
      return true;
    }else{
      return false;
    }
  }
}
