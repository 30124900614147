<app-column-two>
    <ng-progress id="progressBar" [color]="progressBar.currentColor" [thick]="true"></ng-progress>
    <ngx-alerts></ngx-alerts>
    <div class="col-md-6 offset-md-3 mt-7" style="margin-top: 50px;">
        <div class="card">
            <div class="card-body">
                <div style="display: flex; justify-content: center; align-items: center;">
                    <div style="margin-left: 15px; text-align: center; font-size: 22px; font-weight: 600; margin-top: 5px; color: rgb(21, 44, 91);">
                        Co-Occurence</div>
                </div>
                <div class="container">
                    <form #coOccurenceForm="ngForm" (ngSubmit)="onGenerateCoOccurenceForm(coOccurenceForm)" class="form mt-5">
                        <div class="row">
                            <div class="col">
                                <div class="row">
                                    <div class="form-group">
                                        <label>Comments File</label>
                                        <div class="input-group mb-2">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">Upload File </span>
                                            </div>
                                            <div class="custom-file">
                                                <input type="file" name="customFile" (change)="onExcelFileChange($event)" class="custom-file-input" ngModel accept=".csv">
                                                <label class="custom-file-label">
                                                <span [hidden]="!fileName">{{fileName}}</span>
                                                <span [hidden]="fileName">Choose File</span>
                                            </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="columns.length">
                                    <div class="form-group">
                                        <label>Column Name</label>
                                        <select name="columnName" [formControl]='columnSelected' required ngModel class="form-select form-select-sm">
                                            <option *ngFor="let column of columns" [value]="column">
                                                {{ column }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label>Sentiment Filter</label>
                                        <select name="sentimentFilter" [formControl]='filterSelected' required ngModel class="form-select form-select-sm">
                                            <option value="positive">Positive</option>
                                            <option value="negative">Negative</option>
                                            <option value="neutral">Neutral</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label>KeyWords File</label>
                                        <div class="input-group mb-2">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">Upload File </span>
                                            </div>
                                            <div class="custom-file">
                                                <input type="file" name="customFileKeyWords" (change)="onExcelFileChangeKeyWords($event)" class="custom-file-input" ngModel accept=".csv">
                                                <label class="custom-file-label">
                                                <span [hidden]="!fileNameKeyWords">{{fileNameKeyWords}}</span>
                                                <span [hidden]="fileNameKeyWords">Choose File</span>
                                            </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="columnsKeyWords.length">
                                    <div class="form-group">
                                        <label>Column Name</label>
                                        <select name="columnNameKeyWords" [formControl]='columnKeyWordsSelected' required ngModel class="form-select form-select-sm">
                                            <option *ngFor="let column of columnsKeyWords" [value]="column">
                                                {{ column }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col d-flex justify-content-end">
                                <button class="btn btn-secondary form-control-sm ml-2" type="submit">
                                    <i *ngIf="refreshing" class="fa fa-spinner fa-spin"></i>
                                    <i *ngIf="!refreshing" class="fa fa-floppy-o"></i>&nbsp;&nbsp;
                                    <span *ngIf="refreshing">Loading...</span>
                                    <span *ngIf="!refreshing">Submit</span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>
</app-column-two>