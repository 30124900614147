import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { UserServiceService } from '../../services/user-service.service';
import { wordCloud } from '../../models/wordcloud.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { ProgressBarService } from 'src/app/shared/services/progress-bar.service';
import { AlertService } from 'ngx-alerts';
import { ModalWordCloudComponent } from '../modals/modal-word-cloud/modal-word-cloud.component';

@Component({
  selector: 'app-word-cloud',
  templateUrl: './word-cloud.component.html',
  styleUrls: ['./word-cloud.component.css']
})
export class WordCloudComponent implements OnInit, AfterViewInit {
  transcribeId!: number;
  image: any;
  jsonData: any = {};
  displayedColumns: string[] = ['select', 'frequency', 'word', 'wordUpdated'];
  public dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel<wordCloud>(true, []);

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(private activatedRoute: ActivatedRoute, private userService: UserServiceService,
    private modalService: NgbModal, private alertService: AlertService, public progressBar: ProgressBarService,
    private sanitizer: DomSanitizer) {
    this.transcribeId = activatedRoute.snapshot.params.id;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
    this.userService.getFrequencyWords(this.transcribeId)
      .subscribe((res) => {
        this.dataSource.data = res;
      });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: wordCloud): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.frequency + 1}`;
  }

  generateWordCloud() {
    this.alertService.info('Generating Word Cloud');
    this.progressBar.startLoading();
    const allWordCloudObserver = {
      next: (x: any) => {
        let objectURL = URL.createObjectURL(x);
        this.image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        this.progressBar.setSuccess();
        this.progressBar.completeLoading();
        const modalRef = this.modalService.open(ModalWordCloudComponent, { size: 'lg' });
        modalRef.componentInstance.image = this.image;
        modalRef.componentInstance.fileBlob = x;
      },
      error: (err: any) => {
        this.progressBar.setError();
        this.alertService.danger("Error");
        this.progressBar.completeLoading();
      }
    };
    this.userService.generateWordCloudById(this.transcribeId).subscribe(allWordCloudObserver);
  }

  generateWordCloudSelected() {
    this.jsonData = this.selection.selected;
    this.alertService.info('Generating Word Cloud');
    this.progressBar.startLoading();
    const WordCloudSelectedObserver = {
      next: (x: any) => {
        let objectURL = URL.createObjectURL(x);
        this.image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        this.progressBar.setSuccess();
        this.progressBar.completeLoading();
        const modalRef = this.modalService.open(ModalWordCloudComponent, { size: 'lg' });
        modalRef.componentInstance.image = this.image;
        modalRef.componentInstance.fileBlob = x;
      },
      error: (err: any) => {
        this.progressBar.setError();
        this.alertService.danger("Please select more than 5 words for generate word cloud");
        this.progressBar.completeLoading();
      }
    };
    this.userService.generateWordCloudSelected(this.jsonData).subscribe(WordCloudSelectedObserver);
   }
}