<app-column-two>
    <ng-progress id="progressBar" [color]="progressBar.currentColor" [thick]="true"></ng-progress>
    <ngx-alerts></ngx-alerts>
    <div class="col-md-6 offset-md-3 mt-7" style="margin-top: 50px;">
        <div class="card">
            <div class="card-body">
                <div style="display: flex; justify-content: center; align-items: center;">
                    <div style="margin-left: 15px; text-align: center; font-size: 22px; font-weight: 600; margin-top: 5px; color: rgb(21, 44, 91);">
                        Update Your Account</div>
                </div>
                <div class="container" *ngIf="userFormGroup">
                    <form [formGroup]="userFormGroup">
                        <div class="form-group controlFrom">
                            <label for="editUserFirstname">First Name</label>
                            <input type="text" name="firstname" required class="form-control" formControlName="first_name" id="editUserFirstname">
                        </div>
                        <div class="form-group controlFrom">
                            <label for="ediUserLastname">Last Name</label>
                            <input type="text" name="lastname" required class="form-control" formControlName="last_name" id="editUserLastname" placeholder="Last Nom">
                        </div>
                        <div class="form-group controlFrom">
                            <label for="ediUserEmail">Email</label>
                            <input type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" name="email" required class="form-control" id="ediUserEmail" formControlName="email">
                        </div>
                        <div class="form-group controlFrom">
                            <label for="editUserPhone">Phone</label>
                            <input type="tel" name="phone" pattern="[0-9]{10}" required formControlName="phone" class="form-control" id="ediUserPhone">
                        </div>
                        <div class="form-group controlFrom">
                            <button (click)="onUpdateUser()" class="btn btn-gold form-control">Save
                                <i class="fa fa-save"></i></button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>
</app-column-two>