import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { AlertService } from 'ngx-alerts';
import { User } from 'src/app/shared/models/user';
import { ProgressBarService } from 'src/app/shared/services/progress-bar.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { Token } from '../../models/token';
import { TokenService } from '../../services/token.service';
import { UserServiceService } from '../../services/user-service.service';

@Component({
  selector: 'app-generate-tokens',
  templateUrl: './generate-tokens.component.html',
  styleUrls: ['./generate-tokens.component.css']
})
export class GenerateTokensComponent implements OnInit {
  public refreshing!: boolean;
  public users!: User[];
  public tokens!: Token[];
  public userSelected: FormControl = new FormControl();

  displayedColumnsTokens: string[] = ['userName', 'token', 'number_attempts', 'delete'];
  public dataSourceTokens = new MatTableDataSource<any>();

  constructor(public progressBar: ProgressBarService, public spinnerService: SpinnerService, private alertService: AlertService, private userService: UserServiceService,
    private tokenService: TokenService) { }

  ngOnInit(): void {
    this.getUsers();
    this.getAllTokens();
  }

  public getUsers() {
    this.userService.getAllUsers()
      .subscribe((response: User[]) => {
        this.users = response;
      }),
      (errorResponse: any) => {
        this.progressBar.setError();
        this.alertService.danger("An error occurred while processing the request.");
        this.progressBar.completeLoading();
        this.refreshing = false;
      }
  }

  public getAllTokens() {
    this.tokenService.getAllTokens()
      .subscribe((response: any[]) => {
        this.tokens = response;
        for (let i = 0; i < response.length; i++) {
          this.userService.getUserById(response[i]["user_id"])
            .subscribe((res: User) => {
              this.tokens[i]['userName'] = res["first_name"] + " " + res["last_name"];
              this.dataSourceTokens.data = this.tokens;
            });
        }
      }),
      (errorResponse: any) => {
        this.progressBar.setError();
        this.alertService.danger("An error occurred while processing the request.");
        this.progressBar.completeLoading();
        this.refreshing = false;
      }
  }

  public generateNewToken() {
    this.refreshing = true;
    const formData = new FormData();
    formData.append("userEmail", this.userSelected.value);
    this.tokenService.generateNewToken(formData)
      .subscribe((response: any) => {
        this.tokens = response;
        this.refreshing = false;
        this.userSelected.reset();
        this.getAllTokens();
      }),
      (errorResponse: any) => {
        this.progressBar.setError();
        this.alertService.danger("An error occurred while processing the request.");
        this.progressBar.completeLoading();
        this.refreshing = false;
      }
  }

  public onDeleteToken(index: any) {
    this.tokenService.deleteTokenById(this.dataSourceTokens.data[index]['id'])
      .subscribe(data => {
        this.tokens.splice(index, 1);
        this.dataSourceTokens.data = this.tokens;
      });
  }

}
