<mat-toolbar>
    <mat-toolbar-row class="justify-content-between">
        <button mat-icon-button (click)="toggleSidebar()">
            <mat-icon>menu</mat-icon>
        </button>
        <div class="row mr-2 ml-auto" [hidden]="isLoggedIn">
            <ul class="row m-0 align-items-center">
                <li>
                    <button mat-button [matMenuTriggerFor]="menu" class="user mt-2 d-flex align-items-center">
                        <!-- <img src="./assets/user.jpg" alt="" class="user-image mr-1 p-2" /> -->

                        {{ user_name }}
                        <mat-icon class="user-image-icon ml-1">keyboard_arrow_down</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="userEdit();">
                            <mat-icon>edit</mat-icon>
                            Edit profile
                        </button>
                        <button mat-menu-item (click)="generateToken();">
                            <mat-icon>shopping_cart</mat-icon>
                            Token
                        </button>
                        <button mat-menu-item (click)="changePassword();">
                            <mat-icon>lock-reset</mat-icon>
                            Change Password
                        </button>
                        <button mat-menu-item (click)="logout();">
                            <mat-icon>exit_to_app</mat-icon>
                            Logout
                        </button>
                    </mat-menu>
                </li>
            </ul>
        </div>
    </mat-toolbar-row>
</mat-toolbar>