import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ProgressBarService } from 'src/app/shared/services/progress-bar.service';
import { AlertService } from 'ngx-alerts';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public showLoading!: boolean;

  constructor(private authService: AuthService, public progressBar: ProgressBarService,
    private alertService: AlertService, private router: Router) { }

  ngOnInit() {
  }

  onSubmit(f: NgForm) {
    this.showLoading = true;
    this.alertService.info('Checking User Info');
    this.progressBar.startLoading();
    const loginObserver = {
      next: (x: any) => {
        this.progressBar.setSuccess();
        this.alertService.success('Logged In');
        this.progressBar.completeLoading();
        if(this.authService.loggedIn()){
          this.router.navigateByUrl('/transcriptions');
        }
        this.showLoading = false;
      },
      error: (err: any) => {
        this.progressBar.setError();
        this.alertService.danger(err.error);
        this.progressBar.completeLoading();
        this.showLoading = false;
      }
    };
    this.authService.login(f.value).subscribe(loginObserver);

  }

}
