import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { JwtHelperService } from "@auth0/angular-jwt";
import { User } from "../models/user";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: "root"
})
export class AuthService {
  helper = new JwtHelperService();
  decodedToken: any;
  currentUser!: User | null;

  constructor(private http: HttpClient) { }

  login(model: any) {
    let apiUrl = environment.apiUrl;
    return this.http.post(apiUrl + "api/auth/login", model).pipe(
      map((response: any) => {
        const user = response;
        if (user) {
          localStorage.setItem("token", user.token);
          localStorage.setItem("user", JSON.stringify(user.userToReturn));
          localStorage.setItem("user_name", JSON.stringify(user.user_name));
          this.decodedToken = this.helper.decodeToken(user.token);
          this.currentUser = user.userToReturn;
        }
      })
    );
  }

  register(model: any) {
    let confirmEmailUrl = environment.confirmEmailUrl;
    let apiUrl = environment.apiUrl;
    let headers = new HttpHeaders({
      confirmEmailUrl: confirmEmailUrl
    });
    let options = { headers: headers };
    return this.http.post(apiUrl + "api/users/create", model, options);
  }

  resetPassword(model: any) {
    let changePasswordUrl = environment.changePasswordUrl;
    let apiUrl = environment.apiUrl;
    let headers = new HttpHeaders({
      changePasswordUrl: changePasswordUrl
    });
    let options = { headers: headers };
    return this.http.post(apiUrl + "api/auth/resetpassword", model, options);
  }

  confirmEmail(model: any) {
    let apiUrl = environment.apiUrl;
    return this.http.post(apiUrl + "api/auth/confirmemail", model);
  }

  changePassword(model: any) {
    let apiUrl = environment.apiUrl;
    return this.http.post(apiUrl + "api/auth/changepassword", model);
  }

  loggedIn() {
    const token = localStorage.getItem("token");
    let tokenBool!: boolean;
    if (token) {
      tokenBool = !this.helper.isTokenExpired(token);
    }
    return tokenBool;
  }
}
