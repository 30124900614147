import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { UserDashboardRoutingModule } from './user-dashboard-routing.module';
import { FormsModule, NgModel, ReactiveFormsModule } from '@angular/forms';
import { UserChangePasswordComponent } from './components/user-change-password/user-change-password.component';
import { NgProgressModule } from 'ngx-progressbar';
import { AlertModule } from 'ngx-alerts';
// import { BsDropdownModule } from 'ngx-bootstrap';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { FrequencyWordsComponent } from './components/frequency-words/frequency-words.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { MatIconModule } from '@angular/material/icon';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbdModalConfirmComponent } from './components/modals/ngbd-modal-confirm/ngbd-modal-confirm.component';
import { WordCloudComponent } from './components/word-cloud/word-cloud.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ModalWordCloudComponent } from './components/modals/modal-word-cloud/modal-word-cloud.component';
import { MatSelectModule } from '@angular/material/select';
import { TranscriptionComponent } from './components/transcription/transcription.component';
import { FileDragNDropDirective } from './services/file-drag-n-drop.directive';
import { DialogConfirmComponent } from './components/modals/dialog-confirm/dialog-confirm.component';
import { NgxFileDragDropModule } from "ngx-file-drag-drop";
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { FileService } from './services/file.service';
import { ModalTranscribeComponent } from './components/modals/modal-transcribe/modal-transcribe.component';
import { ModalTranscriptionComponent } from './components/modals/modal-transcription/modal-transcription.component';
import { ModalFolderComponent } from './components/modals/modal-folder/modal-folder.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from '@angular/material/menu';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { FileExplorerComponent } from './components/file-explorer/file-explorer.component';
import { RenameDialogComponent } from './components/modals/rename-dialog/rename-dialog.component';
import { UserFolderComponent } from './components/user-folder/user-folder.component';
import { ModalFolderDeletionComponent } from './components/modals/modal-folder-deletion/modal-folder-deletion.component';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { DeleteDialogComponent } from './components/modals/delete-dialog/delete-dialog.component';
import { WordembeddingComponent } from './components/wordembedding/wordembedding.component';
import { SentimentanalysisComponent } from './components/sentimentanalysis/sentimentanalysis.component';
import { CustomWordCloudComponent } from './components/custom-word-cloud/custom-word-cloud.component';
import { CoOccurenceComponent } from './components/co-occurence/co-occurence.component';
import { DemoComponent } from './components/demo/demo.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ContactComponent } from './components/contact/contact.component';
import { GenerateTokensComponent } from './components/generate-tokens/generate-tokens.component';

@NgModule({
  declarations: [
    TranscriptionComponent,
    EditProfileComponent,
    UserChangePasswordComponent,
    FrequencyWordsComponent,
    ModalTranscribeComponent,
    NgbdModalConfirmComponent,
    WordCloudComponent,
    ModalWordCloudComponent,
    TranscriptionComponent,
    ModalTranscriptionComponent,
    FileDragNDropDirective,
    DialogConfirmComponent,
    ModalFolderComponent,
    FileExplorerComponent,
    RenameDialogComponent,
    UserFolderComponent,
    ModalFolderDeletionComponent,
    EditProfileComponent,
    DeleteDialogComponent,
    WordembeddingComponent,
    SentimentanalysisComponent,
    CustomWordCloudComponent,
    CoOccurenceComponent,
    DemoComponent,
    DashboardComponent,
    ContactComponent,
    GenerateTokensComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    UserDashboardRoutingModule,
    FlexLayoutModule,
    MatCardModule,
    NgProgressModule,
    MatSelectModule,
    NgxFileDragDropModule,
    MatPaginatorModule,
    MatTableModule,
    MatDialogModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatGridListModule,
    MatMenuModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatDividerModule,
    MatInputModule,
    MatIconModule,
    MatListModule,
    MatCheckboxModule,
    MatSnackBarModule,
    NgbModule,
    MDBBootstrapModule.forRoot(),
    BsDropdownModule.forRoot(),

    // Specify your library as an import (set timeout to -1 for unlimited timeout, the message can only be closed by the user clicking on it)
    AlertModule.forRoot({ maxMessages: 5, timeout: 8000, positionX: 'right' })
  ],
  exports: [
    TranscriptionComponent,
    FrequencyWordsComponent,
    WordCloudComponent,
    FileExplorerComponent,
    EditProfileComponent
  ],

  entryComponents: [RenameDialogComponent],
  providers: [FrequencyWordsComponent, UserFolderComponent, FileService],
})
export class UserDashboardModule { }
