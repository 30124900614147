<app-column-two>
    <ng-progress id="progressBar" [color]="progressBar.currentColor" [thick]="true"></ng-progress>
    <ngx-alerts></ngx-alerts>
    <div class="container">
        <div class="row">
            <div class="col-xl-6 mb-3 mb-lg-5">
                <div class="card">
                    <div class="card-body">
                        <span class="d-block text-center font-weight-bold mb-2">Choose your user</span>
                        <form #generateTokenForm="ngForm" (ngSubmit)="generateNewToken()" class="form">
                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Users</label>
                                        <select name="user" [formControl]='userSelected' class="form-select form-select-sm">
                                            <option *ngFor="let user of users" [value]="user.email">
                                                {{ user.first_name }} {{ user.last_name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col d-flex justify-content-end">
                                    <button class="btn btn-secondary form-control-sm ml-2" type="submit">
                                        <i *ngIf="refreshing" class="fa fa-spinner fa-spin"></i>
                                        <i *ngIf="!refreshing" class="fa fa-floppy-o"></i>&nbsp;&nbsp;
                                        <span *ngIf="refreshing">Loading...</span>
                                        <span *ngIf="!refreshing">Generate</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="col-xl-6 mb-3 mb-lg-5">
                <div class="card">
                    <div class="card-body">
                        <span class="d-block text-center font-weight-bold mb-4">All users with their Tokens</span>
                        <table mat-table [dataSource]="dataSourceTokens" class="mat-elevation-z8">

                            <ng-container matColumnDef="userName">
                                <th mat-header-cell *matHeaderCellDef> User </th>
                                <td mat-cell *matCellDef="let element"> {{element.userName}} </td>
                            </ng-container>

                            <ng-container matColumnDef="token">
                                <th mat-header-cell *matHeaderCellDef> Token </th>
                                <td mat-cell *matCellDef="let element"> {{element.token}} </td>
                            </ng-container>

                            <ng-container matColumnDef="number_attempts">
                                <th mat-header-cell *matHeaderCellDef> NB </th>
                                <td mat-cell *matCellDef="let element"> {{element.number_attempts}} </td>
                            </ng-container>

                            <ng-container matColumnDef="delete" style="text-align: center;">
                                <th mat-header-cell *matHeaderCellDef style="text-align: center;"></th>
                                <td mat-cell *matCellDef="let i = index" style="text-align: center;">
                                    <i class="fa fa-trash" (click)="onDeleteToken(i)" style="font-size: 20px; color: red;"></i>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumnsTokens"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumnsTokens;"></tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-column-two>