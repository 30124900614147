import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { Transcript } from 'src/app/user-dashboard/models/transcript.model';
import { UserServiceService } from 'src/app/user-dashboard/services/user-service.service';
import { environment } from 'src/environments/environment';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';
import { ModalFolderComponent } from '../modal-folder/modal-folder.component';
import { catchError, map, startWith } from 'rxjs/operators';
import { of } from 'rxjs';
import { AppDataState, DataStateEnum } from 'src/app/user-dashboard/state/uploadaudio.state';
import { ProgressBarService } from 'src/app/shared/services/progress-bar.service';
import { Router } from '@angular/router';
import { AlertService } from 'ngx-alerts';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { FileService } from 'src/app/user-dashboard/services/file.service';

@Component({
  selector: 'app-modal-transcription',
  templateUrl: './modal-transcription.component.html',
  styleUrls: ['./modal-transcription.component.css']
})
export class ModalTranscriptionComponent implements OnInit {
  public files: any[] = [];
  userEmail: any;
  allFolder: any[] = [];
  allLanguage!: any[];
  transcript$: Observable<AppDataState<Transcript[]>> | null = null;
  jsonData: any = {};
  selectControlLanguage: FormControl = new FormControl();
  selectControlService: FormControl = new FormControl();
  selectControlFolder: FormControl = new FormControl();

  currentRoot!: any;
  currentPath!: any;

  constructor(public activeModal: NgbActiveModal, private modalService: NgbModal, public dialog: MatDialog,
    private userService: UserServiceService, private http: HttpClient, public progressBar: ProgressBarService,
    private alertService: AlertService, private router: Router, public spinnerService: SpinnerService, public fileService: FileService) {
    this.userEmail = localStorage.getItem('user')?.split('"')[1];
  }

  ngOnInit(): void {
    this.userService.getAllLanguage()
      .subscribe((res) => {
        this.allLanguage = res;
      });
    this.userService.getAllFolder(this.userEmail)
      .subscribe((res) => {
        this.allFolder = res;
      });
  }

  onFileChange(event: any) {
    this.files = Object.keys(event.target.files).map((key: any) => event.target.files[key]);
  }

  deleteFile(f: any) {
    this.files = this.files.filter(function (w) { return w.name != f.name });
  }

  openConfirmDialog(pIndex: any): void {
    const modalRef = this.modalService.open(DialogConfirmComponent);
    modalRef.componentInstance.fName = this.files[pIndex].name;
    modalRef.componentInstance.fIndex = pIndex;
    modalRef.result.then(
      () => {
        if (modalRef.result !== undefined) {
          this.deleteFromArray(modalRef.result);
        }
        // actions
      },
      () => {
        // actions
      });
  }

  deleteFromArray(index: any) {
    // console.log(this.files);
    this.files.splice(index, 1);
  }

  createFolder() {
    const modalRef = this.modalService.open(ModalFolderComponent);
    modalRef.result.then(
      () => {
        this.userService.getAllFolder(this.userEmail)
          .subscribe((res) => {
            this.allFolder = res;
            for (let i = 0; i < res.length; i++) {
              this.fileService.add({ id: "FOLDER" + res[i]['id'], name: res[i]['name'], isFolder: true, parent: 'root' });
            }
            this.updateFileElementQuery();
          });
        // actions
      },
      () => {
        // actions
      });
  }

  updateFileElementQuery() {
    this.fileService.queryInFolder(this.currentRoot ? this.currentRoot.id : 'root');
  }

  onSubmit() {
    let apiUrl = environment.apiUrl;
    const formData = new FormData();

    for (var i = 0; i < this.files.length; i++) {
      formData.append("file[]", this.files[i]);
    }
    formData.append("lang", this.selectControlLanguage.value);
    formData.append("service", this.selectControlService.value);
    formData.append("folderId", this.selectControlFolder.value);
    formData.append("user", this.userEmail);

    this.spinnerService.show()
    const transcriptObserver = {
      next: (x: any) => {
        this.router.navigateByUrl('/frequencywords/' + this.selectControlFolder.value);
        this.activeModal.close('Ok click')
      },
      error: (err: any) => {
        this.progressBar.setError();
        this.alertService.danger(err.error);
        this.progressBar.completeLoading();
      }
    };
    this.http.post<Transcript[]>(apiUrl + "api/transcription", formData).subscribe(transcriptObserver);
  }

}
