import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChangePasswordComponent } from './auth/components/change-password/change-password.component';
import { ConfirmEmailComponent } from './auth/components/confirm-email/confirm-email.component';
import { LoginComponent } from './auth/components/login/login.component';
import { RegisterComponent } from './auth/components/register/register.component';
import { ResetPasswordComponent } from './auth/components/reset-password/reset-password.component';
import { HomeComponent } from './pages/home/home.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { TokenComponent } from './shared/components/token/token.component';
import { AuthGuard } from './shared/services/auth-guard.service';
import { LoggedInAuthGuard } from './shared/services/logged-in-auth-guard.service';
import { CoOccurenceComponent } from './user-dashboard/components/co-occurence/co-occurence.component';
import { ContactComponent } from './user-dashboard/components/contact/contact.component';
import { DashboardComponent } from './user-dashboard/components/dashboard/dashboard.component';
import { DemoComponent } from './user-dashboard/components/demo/demo.component';
import { EditProfileComponent } from './user-dashboard/components/edit-profile/edit-profile.component';
import { FrequencyWordsComponent } from './user-dashboard/components/frequency-words/frequency-words.component';
import { GenerateTokensComponent } from './user-dashboard/components/generate-tokens/generate-tokens.component';
import { SentimentanalysisComponent } from './user-dashboard/components/sentimentanalysis/sentimentanalysis.component';
import { TranscriptionComponent } from './user-dashboard/components/transcription/transcription.component';
import { UserChangePasswordComponent } from './user-dashboard/components/user-change-password/user-change-password.component';
import { UserFolderComponent } from './user-dashboard/components/user-folder/user-folder.component';
import { WordCloudComponent } from './user-dashboard/components/word-cloud/word-cloud.component';
import { WordembeddingComponent } from './user-dashboard/components/wordembedding/wordembedding.component';


const routes: Routes = [
  { path: 'home', component: HomeComponent, canActivate: [LoggedInAuthGuard] },
  { path: '', redirectTo: "home", pathMatch: "full" },
  { path: 'login', component: LoginComponent, canActivate: [LoggedInAuthGuard] },
  { path: 'register', component: RegisterComponent, canActivate: [LoggedInAuthGuard] },
  { path: 'reset-password', component: ResetPasswordComponent, canActivate: [LoggedInAuthGuard] },
  { path: 'confirm-email', component: ConfirmEmailComponent },
  { path: 'change-password', component: ChangePasswordComponent },
  { path: 'transcriptions', component: TranscriptionComponent, canActivate: [AuthGuard] },
  { path: 'user-change-password', component: UserChangePasswordComponent, canActivate: [AuthGuard] },
  { path: 'frequencywords/:id', component: FrequencyWordsComponent, canActivate: [AuthGuard] },
  { path: 'userfolder', component: UserFolderComponent, canActivate: [AuthGuard] },
  { path: "wordcloud/:id", component: WordCloudComponent, canActivate: [AuthGuard] },
  { path: "wordembedding", component: WordembeddingComponent, canActivate: [AuthGuard] },
  { path: "sentimentanalysis", component: SentimentanalysisComponent, canActivate: [AuthGuard] },
  { path: "co-occurence", component: CoOccurenceComponent, canActivate: [AuthGuard] },
  { path: 'editprofile', component: EditProfileComponent, canActivate: [AuthGuard] },
  { path: 'token', component: TokenComponent, canActivate: [AuthGuard] },
  { path: "generate-tokens", component: GenerateTokensComponent },
  { path: "dashboard", component: DashboardComponent },
  { path: "contact", component: ContactComponent },
  { path: "demo", component: DemoComponent },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
