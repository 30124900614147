import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AlertService } from 'ngx-alerts';
import { Token } from 'src/app/user-dashboard/models/token';
import { TokenService } from 'src/app/user-dashboard/services/token.service';
import { UserServiceService } from 'src/app/user-dashboard/services/user-service.service';
import { ProgressBarService } from '../../services/progress-bar.service';
import { SpinnerService } from '../../services/spinner.service';

@Component({
  selector: 'app-token',
  templateUrl: './token.component.html',
  styleUrls: ['./token.component.css']
})
export class TokenComponent implements OnInit {
  public tokens!: Token[];

  displayedColumnsToken: string[] = ['token'];
  public dataSourceToken = new MatTableDataSource<any>();

  constructor(public progressBar: ProgressBarService, public spinnerService: SpinnerService, private alertService: AlertService, private userService: UserServiceService,
    private tokenService: TokenService) { }

  ngOnInit(): void {
    this.getTokenByUser();
  }

  public getTokenByUser() {
    this.tokenService.getAllTokens()
      .subscribe((response: any[]) => {
        this.tokens = response;
        this.dataSourceToken.data = this.tokens;
      }),
      (errorResponse: any) => {
        this.progressBar.setError();
        this.alertService.danger("An error occurred while processing the request.");
        this.progressBar.completeLoading();
      }
  }

}
