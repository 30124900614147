import { Component, OnInit } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'ngx-alerts';
import { Subscription } from 'rxjs';
import { ProgressBarService } from 'src/app/shared/services/progress-bar.service';
import { FileService } from '../../services/file.service';
import { UserServiceService } from '../../services/user-service.service';
import { ModalFolderDeletionComponent } from '../modals/modal-folder-deletion/modal-folder-deletion.component';

@Component({
  selector: 'app-user-folder',
  templateUrl: './user-folder.component.html',
  styleUrls: ['./user-folder.component.css']
})
export class UserFolderComponent implements OnInit {
  public displayedColumns: string[] = ['demo-position', 'demo-name', 'showTranscribe', 'download', 'delete'];
  public dataSource = new MatTableDataSource<any>();
  public userEmail: any;
  public folderId!: number;
  public jsonData: any = {};
  public currentRoot!: any;
  public currentPath!: any;
  public fileName!: string | null;
  public excelFile!: File;
  public columns: any[] = [];
  public files: any;
  public refreshing!: boolean;
  private subscriptions: Subscription[] = [];
  public filterSelected: FormControl = new FormControl();
  public columnSelected: FormControl = new FormControl();

  constructor(private userService: UserServiceService, private router: Router, public progressBar: ProgressBarService, private alertService: AlertService,
    private modalService: NgbModal, public fileService: FileService) {
    this.userEmail = localStorage.getItem('user')?.split('"')[1];
  }

  ngOnInit(): void {
    this.userService.getAllFolder(this.userEmail)
      .subscribe((res) => {
        this.dataSource.data = res;
      });
  }

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public showTranscribe(index: number) {
    this.folderId = this.dataSource.data[index]['id'];
    this.router.navigateByUrl('/frequencywords/' + this.folderId);
  }

  public deleteFolder(index: number) {
    const modalRef = this.modalService.open(ModalFolderDeletionComponent);
    modalRef.componentInstance.folder = this.dataSource.data[index]['name'];
    modalRef.componentInstance.folderId = this.dataSource.data[index]['id'];
    modalRef.result.then(
      () => {
        this.userService.getAllFolder(this.userEmail)
          .subscribe((res) => {
            this.fileService.delete(this.dataSource.data[index]['id']);
            for (let i = 0; i < res.length; i++) {
              this.fileService.add({ id: "FOLDER" + res[i]['id'], name: res[i]['name'], isFolder: true, parent: 'root' });
            }
            this.updateFileElementQuery();
            this.dataSource.data = res;
          });
        // actions
      },
      () => {
        // actions
      });
  }

  public downloadFolder(index: number) {
    this.folderId = this.dataSource.data[index]['id']
    this.userService.getTranscribe(this.folderId)
      .subscribe((res) => {
        this.jsonData = res;
        for (let i = 0; i < this.jsonData.length; i++) {
          delete this.jsonData[i].id;
        }
        this.userService.exportAsExcelFile(this.jsonData);
      });
  }

  public updateFileElementQuery() {
    this.fileService.queryInFolder(this.currentRoot ? this.currentRoot.id : 'root');
  }

  //only number will be add
  public keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  public onExcelFileChange(event: any): void {
    const filesEvent = Object.keys(event.target.files).map((key: any) => event.target.files[key]);
    this.files = filesEvent[0];
    this.fileName = this.files.name;
    this.excelFile = this.files;
    
    this.progressBar.startLoading();
    const formData = new FormData();
    formData.append("excelFile", this.excelFile);
    this.subscriptions.push(
      this.userService.getDataColumnsNameCSV(formData).subscribe(
        (response: any) => {
          this.columns = response;
          this.progressBar.setSuccess();
          this.progressBar.completeLoading();
        },
        (errorResponse: any) => {
          this.progressBar.setError();
          this.alertService.danger("An error occurred while processing the request.");
          this.progressBar.completeLoading();
        }
      )
    );
  }

  public onGenerateCustomWordCloud(customWordCloudForm: NgForm): void {
    this.progressBar.startLoading();
    this.refreshing = true;
    const formData = new FormData();

    formData.append("customFile", this.excelFile);
    formData.append("columnName", this.columnSelected.value);
    formData.append("filterName", this.filterSelected.value);
    formData.append("numberOfWords", customWordCloudForm.value['numberOfWords']);
    this.subscriptions.push(
      this.userService.generateCustomWordCloud(formData).subscribe(
        (response: Blob) => {
          const file = new window.Blob([response]);
          const downloadAncher = document.createElement("a");
          downloadAncher.style.display = "none";
          const fileURL = URL.createObjectURL(file);
          downloadAncher.href = fileURL;
          downloadAncher.download = `CustomWordCloud_${new Date()}.csv`;
          downloadAncher.click();
          customWordCloudForm.reset();
          this.columns = [];
          this.fileName = null;
          this.refreshing = false;
          this.progressBar.setSuccess();
          this.progressBar.completeLoading();
        },
        (errorResponse: any) => {
          this.progressBar.setError();
          this.alertService.danger("An error occurred while processing the request.");
          this.progressBar.completeLoading();
          this.refreshing = false;
        }
      )
    );
  }

}