<div class="modal-header">
    <h4 class="modal-title">Word Cloud</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <img [src]="image" class="img-thumbnail" alt="Cinque Terre">
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div>
<div class="modal-footer" style="float: left; margin-top: -71px;">
    <button type="button" class="btn btn-outline-dark" (click)="downloadAllWordCloud()">Download</button>
</div>