<ng-progress id="progressBar" [color]="progressBar.currentColor" [thick]="true"></ng-progress>
<ngx-alerts></ngx-alerts>
<app-column-two>
    <nav class="navbar navbar-light navbar-expand-md bg-light justify-content-md-center justify-content-start">
        <div class="navbar-collapse collapse justify-content-between align-items-center w-100" id="collapsingNavbar2">
            <ul class="navbar-nav text-md-center text-left">
                <button class="btn btn-sm btn-outline-info" (click)="generateWordCloud()">Generate Word Cloud For All
                    Words</button>
                <button class="btn btn-sm btn-outline-info" (click)="generateWordCloudSelected()">Generate Word Cloud
                    For Words Selected</button>
            </ul>
            <ul class="nav navbar-nav flex-row justify-content-md-center justify-content-start flex-nowrap">
                <!-- Search form -->
                <form class="form-inline md-form form-sm active-cyan-2" style="display: flex;">
                    <input class="form-control form-control-sm mr-3 w-75" type="text" placeholder="Search" aria-label="Search" #input (keyup)="applyFilter($event)">
                    <button class="btn btn-sm btn-outline-info ml-2">
                        <span class="fa fa-search"></span>
                    </button>
                </form>
            </ul>
        </div>
    </nav>
    <div class="mat-elevation-z8" style="width: 90%; margin-left: 60px; margin-top: 60px;">
        <table mat-table [dataSource]="dataSource">

            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="frequency">
                <th mat-header-cell *matHeaderCellDef> Frequency </th>
                <td mat-cell *matCellDef="let element"> {{element.frequency}} </td>
            </ng-container>

            <ng-container matColumnDef="word">
                <th mat-header-cell *matHeaderCellDef> Original Word </th>
                <td mat-cell *matCellDef="let element"> {{element.word}} </td>
            </ng-container>

            <ng-container matColumnDef="wordUpdated">
                <th mat-header-cell *matHeaderCellDef> Update Word </th>
                <td mat-cell *matCellDef="let element">
                    <input class="form-control form-control-sm mr-3 w-75" style="text-align: right;" type="text" [(ngModel)]="element.wordUpdated" />
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)"> -->
            </tr>
        </table>

        <mat-paginator [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons aria-label="Select page of periodic elements">
        </mat-paginator>
    </div>
    <!-- {{selection.selected|json}} -->
</app-column-two>