<div class="modal-header">
    <h4 class="modal-title">Rename Element</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <input type="text" class="form-control" value="{{elementName}}" #ElementName>
</div>
<div id="closeId" class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
    <button type="button" class="btn btn-gold" (click)="updateName()">Rename</button>
</div>