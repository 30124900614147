import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'ngx-alerts';
import { ProgressBarService } from 'src/app/shared/services/progress-bar.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { Contact } from '../../models/contact';
import { DemoService } from '../../services/demo.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  public first_name!: string;
  public last_name!: string;
  public company!: string;
  public function!: string;
  public email!: string;
  public public: boolean = false;
  public disableFields: boolean = false;

  constructor(private demoService: DemoService, public progressBar: ProgressBarService, public spinnerService: SpinnerService,
    private alertService: AlertService, private router: Router) { }

  ngOnInit(): void {
  }

  submitForm(form: Contact) {
    const formData = new FormData();
    formData.append("first_name", form.first_name);
    formData.append("last_name", form.last_name);
    formData.append("company", form.company);
    formData.append("function", form.function);
    formData.append("email", form.email);
    if (this.public == false) {
      this.demoService.addNewContact(formData).subscribe(
        (response: void) => {
          this.router.navigateByUrl('/demo');
        },
        (errorResponse: any) => {
          this.progressBar.setError();
          this.alertService.danger("An error occurred while processing the request.");
          this.progressBar.completeLoading();
        }
      );
    } else {
      this.router.navigateByUrl('/demo');
    }
  }

}
